import React, { useEffect, useRef, useState } from 'react'
import QRCodeReact from 'qrcode.react'

import Loading from '../../components/loading'
import { NavInSites } from '../../components/nav'
import { RawJsonForm } from '../../components/rawJsonForm'
import * as api from '../../lib/api'
import { apiCache, notifications } from '../../stores'
import { t } from '../../stores/i18n.store'
import { areEqual } from '../../utils/tools'
import Page from '../page'
import { SiteCard } from './siteCard'

export const BundleForm = props => {
  const bundleId = useRef(props?.match?.params?.bundleUid || 'new').current

  const sitesRef = useRef(sortByName(filterExtists(apiCache.sites.list())) || [])
  const experiencesRef = useRef(filterExtists(apiCache.experiences.list()) || [])

  const [formMode, setFormMode] = useState('form')
  const [bundle, setBundle] = useState({ ...(apiCache.bundles.get(bundleId) || {}) })
  const [data, setData] = useState({ name: '', description: '', sitesExperiences: [] })
  const [newSites, setNewSites] = useState([])

  const initialData = useRef(data)

  const [isLoading, setIsLoading] = useState(true)
  const [needUpdate, setNeedUpdate] = useState(false)

  // Mise à jour des données qui n'étaient pas déjà en cache lors du chargement du composant.
  useEffect(() => {
    Promise.resolve()
      .then(async () => {
        const promises = []
        if (!bundle?._id) promises.push(api.bundles.list())
        if (!sitesRef.current?.length) promises.push(api.sites.list())
        if (!experiencesRef.current?.length) promises.push(api.experiences.list())
        // À la fin des requêtes, tout est récupéré depuis le cache comme à la création du composant.
        await Promise.all(promises).then(() => {
          sitesRef.current = sortByName(filterExtists(apiCache.sites.list())) || []
          experiencesRef.current = filterExtists(apiCache.experiences.list()) || []
        })
        return { ...(apiCache.bundles.get(bundleId) || {}) } // Le bundle est un state, avec une mise à jour asynchrone.
      })
      // Une fois toutes les données récupérées, data est mis à jour avec les données formatées .
      .then(async bundle => {
        const data = {
          name: bundle.name || '',
          description: bundle.description || '',
          sitesExperiences: toBundleArray(bundle, sitesRef.current, experiencesRef.current)
        }
        initialData.current = JSON.parse(JSON.stringify(data)) // Copie profonde permettant de comparer les données.
        setData(data)
        setBundle(bundle)
      })
      .catch(error => console.error(error)) // En cas d'erreur.
      .finally(() => setIsLoading(false)) // Arrêt du chargement initial.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Lors des modifications de "data".
  useEffect(() => {
    // S'il y a de nouveaux sites, on a forcément des modifications non enregistrées.
    if (newSites.length > 0) setNeedUpdate(true)
    // Sinon, il y a des modifications si les données sont différentes des données initiales.
    else {
      const equal = areEqual(data, initialData.current)
      if (needUpdate === equal) setNeedUpdate(!equal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, newSites])

  // Affiche un message en cas de tentative de quitter la page si des données ont été modifiées.
  useEffect(() => {
    const txt = t('unsaved')
    const onExit = event => needUpdate && (event.returnValue = txt)
    const unblock = props.history.block(location => {
      if (needUpdate) {
        if (window.confirm(txt)) {
          unblock()
          props.history.push(location.pathname)
        } else return false
      }
    })
    window.addEventListener('beforeunload', onExit)
    return () => void (window.removeEventListener('beforeunload', onExit), unblock())
  }, [needUpdate, props.history])

  const onDelete = async () => {
    if (window.confirm(t('alert-delete'))) {
      await api.bundles.remove(bundleId)
      if (props?.history?.push) props.history.push('/bundle/list')
    }
  }

  const onSave = async formData => {
    const toSave = formData
      ? { ...formData } // C'est qu'on modifie en JSON.
      : { ...bundle, name: data.name, description: data.description, ...toBundleObect([...data?.sitesExperiences, ...newSites]) }

    try {
      const savedBundle = await api.bundles.upsert(toSave)
      setBundle({ ...savedBundle })
      const newData = {
        name: savedBundle.name || '',
        description: savedBundle.description || '',
        sitesExperiences: toBundleArray(savedBundle, sitesRef.current, experiencesRef.current)
      }
      setNewSites([])
      initialData.current = JSON.parse(JSON.stringify(newData))
      setData(newData)
      setNeedUpdate(false)

      const id = Date.now()
      notifications.set(id, { id, type: 'success', title: t('save-success'), autoClose: 1500 })

      if (props?.history?.push) setTimeout(() => props.history.push(`/bundle/${bundle._id || ''}`), 100)
    } catch (error) {
      const id = Date.now()
      notifications.set(id, {
        id,
        type: 'danger',
        title: t('save-error'),
        content: error.toString() || 'Erreur',
        autoClose: 2500
      })
    }
  }

  const editSite = (siteIndex, site) =>
    setData(state => {
      state.sitesExperiences[siteIndex] = site
      return { ...state }
    })

  const editNewSite = (siteIndex, site) =>
    setNewSites(state => {
      state[siteIndex] = site
      return [...state]
    })

  return (
    <Page>
      <NavInSites />
      <div id="content" style={{ marginBottom: 30 }}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {!bundle._id && bundleId !== 'new' ? (
              // Si le bundle n'existe pas et n'est pas nouveau.
              <div>{t('bundle-not-found')}</div>
            ) : (
              // Corps du composant.
              <>
                <div style={{ marginBottom: 20, display: 'flex' }}>
                  <div className="btn-group" role="group" style={{ flex: 1 }}>
                    <button
                      className={`btn ${formMode === 'json' ? 'btn-info' : 'btn-default'}`}
                      onClick={() => setFormMode('json')}
                    >
                      {t('mode-json')}
                    </button>
                    <button
                      className={`btn ${formMode === 'form' ? 'btn-info' : 'btn-default'}`}
                      onClick={() => setFormMode('form')}
                    >
                      {t('mode-form')}
                    </button>
                  </div>

                  {bundle?._id && (
                    <button className="btn btn-danger" onClick={onDelete}>
                      <i className="fa fa-trash"></i>
                    </button>
                  )}
                </div>

                {formMode === 'json' ? (
                  <RawJsonForm value={bundle} onChange={bundle => setBundle(bundle)} onSubmit={onSave} />
                ) : (
                  <>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                      <div style={{ flex: 1, marginBottom: 20, minWidth: 300 }}>
                        <label className="control-label">{t('bundle-title')}*</label>
                        <input
                          className="form-control"
                          label={t('bundle-title')}
                          type="text"
                          value={data.name || ''}
                          onChange={e => setData({ ...data, name: e.target.value })}
                        />
                        <label className="control-label" style={{ marginTop: 20 }}>
                          {t('bundle-description')}
                        </label>
                        <textarea
                          className="form-control"
                          rows="5"
                          value={data.description || ''}
                          onChange={e => setData({ ...data, description: e.target.value })}
                        />
                      </div>
                      {!!bundle._id && (
                        <QRCodeReact
                          value={'https://workshop.explor.games/bundle/' + bundle._id}
                          style={{ marginBottom: 20, marginLeft: '5%', marginRight: '5%' }}
                          size={170}
                          level="Q"
                        />
                      )}
                    </div>

                    <h3 style={{ marginTop: 20 }}>{t('bundle-sites-title')}</h3>
                    <div style={{ border: '1px solidrgb(79, 83, 208)' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
                        <h4>{t('bundle-add-site-title')}</h4>
                        <select
                          className="form-control"
                          style={{ flex: '0 1 auto', width: 'auto', marginLeft: 10, marginRight: 20 }}
                          onChange={e => {
                            if (!e.target.value) return
                            const siteId = e.target.value
                            const site = sitesRef.current.find(s => s._id === siteId)
                            const experiences = sortByName(
                              (experiencesRef.current || [])
                                .filter(exp => exp.siteId === siteId)
                                .map(exp => ({
                                  id: exp._id,
                                  name: exp.name + (exp.locale ? ` (${exp.locale})` : ''),
                                  selected: false,
                                  doNotStart: false
                                }))
                            )
                            setNewSites(state => sortByName([...state, { id: siteId, name: site.name, experiences }]))
                          }}
                        >
                          <option value={undefined}></option>
                          {sitesRef.current
                            .filter(
                              site =>
                                !data.sitesExperiences.map(s => s.id).includes(site._id) &&
                                !newSites.map(s => s.id).includes(site._id)
                            )
                            .map(site => (
                              <option value={site._id} key={site._id}>
                                {site.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div style={{ overflow: 'auto' }}>
                      <table style={{ tableLayout: 'fixed', width: '100%', minWidth: 500, maxWidth: 1200 }}>
                        <thead style={{ borderBottom: '1px solid #ddd' }}>
                          <tr style={{ opacity: 0 }}>
                            <th style={{ width: 40 }}>#</th>
                            <th style={{ width: 40 }}>#</th>
                            <th style={{ width: 'auto' }}>#</th>
                            <th style={{ width: 100 }}>#</th>
                            <th style={{ width: 50 }}>#</th>
                            <th style={{ width: 50 }}>#</th>
                          </tr>
                          <tr>
                            <th colSpan={3} style={{ paddingTop: 15, paddingBottom: 15 }}>
                              {t('bundle-table-name')}
                            </th>
                            <th style={{ textAlign: 'center' }}>{t('bundle-do-not-start')}</th>
                            <th style={{ textAlign: 'center' }}></th>
                            <th style={{ textAlign: 'center' }}></th>
                          </tr>
                        </thead>
                        <tbody style={{ opacity: 0 }}>
                          <tr>
                            <td>#</td>
                          </tr>
                        </tbody>

                        {newSites.length > 0 && (
                          <>
                            {newSites.map((site, siteIndex) => (
                              <SiteCard
                                site={site}
                                onChange={site => editNewSite(siteIndex, site)}
                                removeSite={() => setNewSites(state => state.filter(s => s.id !== site.id))}
                                key={`new-${site.id}`}
                              />
                            ))}
                            <tbody style={{ borderTop: `1px solid #ddd` }}>
                              <tr style={{ opacity: 0 }}>
                                <td>#</td>
                              </tr>
                            </tbody>
                          </>
                        )}

                        {data.sitesExperiences.map((site, siteIndex) => (
                          <SiteCard
                            site={site}
                            onChange={site => editSite(siteIndex, site)}
                            removeSite={() => {
                              setData(state => ({
                                ...state,
                                sitesExperiences: state.sitesExperiences.filter(s => s.id !== site.id)
                              }))
                            }}
                            key={site.id}
                          />
                        ))}
                      </table>
                    </div>
                    {data.sitesExperiences.length === 0 && <p style={{ marginTop: 20 }}>{t('bundle-no-data')}</p>}

                    <div style={{ position: 'fixed', bottom: 5, right: 5 }}>
                      <button type="submit" className="btn btn-block btn-primary" onClick={() => onSave()}>
                        {t('save')}
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Page>
  )
}

const filterExtists = array => (array || []).filter(item => item.deleted !== true)
const normalize = (str = '') => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^a-zA-Z0-9]/g, '').toLowerCase() // prettier-ignore
const sortByName = (array = []) => [...array].sort((a, b) => normalize(a.name).localeCompare(normalize(b.name)))

/**
 * Crée un tableau à partir de l'objet "bundle", des sites et des expériences, pour avoir les noms et pouvoir le trier.\
 * Type de la répoinse : { id: string; name: string; experiences: { id: string; name: string; selected?: boolean; doNotStart?: boolean }[] }[]
 */
const toBundleArray = (bundle, sites = [], experiences = []) => {
  // 1. Construction de la liste à retourner, en partant de la liste des sites. On sera OK pour "bundle.sitesExperiences" et "bundle.sites".
  const sitesArray = sites.reduce((acc, site) => {
    // Récupération des données si le site est dans "bundle.sitesExperiences".
    const siteExps = bundle.sitesExperiences?.[site._id] || []
    // Le site sera ajouté dans la liste s'il est dans "bundle.sites" ou dans "bundle.sitesExperiences".
    if (bundle.sites?.includes(site._id) || siteExps.length > 0) {
      const exps = sortByName(
        experiences
          // Récupération de la liste de toutes les expériences du site.
          .filter(exp => exp.siteId === site._id)
          // Création de l'objet pour chaque expérience.
          .map(exp => {
            const siteExp = siteExps.find(e => e.id === exp._id)
            return {
              id: exp._id,
              name: exp.name + (exp.locale ? ` (${exp.locale})` : ''),
              selected: siteExps.length > 0 ? !!siteExp : true, // Sélectionnée si dans "siteExps", ou si siteExps est vide.
              doNotStart: siteExp?.doNotStart || false
            }
          })
      )
      acc.push({ id: site._id, name: site.name, experiences: exps })
    }
    return acc
  }, [])

  // 2. Ajout des éléments dans "bundle.experiences".
  ;(bundle.experiences || []).forEach(expId => {
    const experience = experiences.find(exp => exp._id === expId)
    if (experience) {
      const site = sites.find(site => site._id === experience.siteId)

      if (site) {
        const siteIndex = sitesArray.findIndex(s => s.id === site._id)
        // Si le site est déjà dans "sitesArray", il faut juste vérifier que l'expérience est sélectionnée.
        if (siteIndex >= 0) {
          const expIndex = sitesArray[siteIndex].experiences?.findIndex(exp => exp.id === expId)
          if (expIndex >= 0) sitesArray[siteIndex].experiences[expIndex].selected = true
        }
        // Sinon, il faut ajouter le site et ses expériences.
        else {
          const exps = sortByName(
            experiences
              .filter(exp => exp.siteId === site._id)
              .map(exp => ({
                id: exp._id,
                name: exp.name + (exp.locale ? ` (${exp.locale})` : ''),
                selected: exp._id === expId,
                doNotStart: false
              }))
          )
          sitesArray.push({ id: site._id, name: site.name, experiences: exps })
        }
      }
    }
  })

  return sortByName(sitesArray)
}

/** Transforme le tableau des sites et expériences en objet compatible avec le bundle, pour l'enregistrer. */
const toBundleObect = (array = []) => {
  const sites = array.filter(site => (site.experiences || []).some(exp => exp.selected))

  return {
    experiences: [], // Avec cette nouvelle écriture les expériences sont supprimées.
    sites: sites.map(site => site.id), // On garde la liste des sites.
    sitesExperiences: sites.reduce((acc, site) => {
      const selectedExperiences = site.experiences.filter(exp => exp.selected)
      const allSelected = selectedExperiences.length === site.experiences.length
      const hasDoNotStart = selectedExperiences.some(exp => exp.doNotStart)

      // Les expériences du site sont ajouté si toutes ne sont pas sélectionnées, ou si au moins une ne doit pas démarrer.
      if (!allSelected || hasDoNotStart) {
        acc[site.id] = selectedExperiences.map(exp => ({ id: exp.id, doNotStart: exp.doNotStart }))
      } // Sinon, toutes les éxpériences du site seront incluses dans le bundle, parce que le site présent dans la propriété "sites".
      return acc
    }, {})
  }
}
