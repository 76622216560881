import React, { Component } from 'react'

import { Route, Switch } from 'react-router-dom'
import { Track, Scenario, Map, Segment, Statistic } from '../'
import ExperienceForm from './form'

import ExperienceAuthorization from './authorization'
import ExperienceTheme from './theme'
import ExperienceThemeNewApp from './themenewapp'
import ExperienceDeploy from './deploy'
import ExperienceEmail from './email'
import { ExperienceResources } from './resource'
import ExperienceTrash from './trash'
import ExperienceText from './text'
import ExperienceTimer from './timer'
import ExperienceCategories from './categories'
import ExperienceCharacters from './character'
import ExperienceQrCodes from './qrcode'
import ExperienceRating from './rating'
import ExperienceBook from './book'
import ExperienceCompare from './book/compare'
import ExperienceDuplicate from './duplicate'
import ExperienceVoice from './voice'
import { ExperienceTags, ExperienceTagForm } from './tag'
import { Popups } from './popups'
import { initSwitchStore, resetSwitchStore } from '../../stores/switch.store'
import { SwitchManager } from './switchManager'

export default class Experience extends Component {
  componentDidMount() {
    initSwitchStore(this.props?.match?.params?.experienceUid)
  }

  componentWillUnmount() {
    resetSwitchStore()
  }

  render() {
    const { match } = this.props
    return (
      <Switch>
        <Route path={match.path + '/autorisations'} component={ExperienceAuthorization} />
        <Route
          path={match.path + '/moderate'}
          component={() => {
            window.location.href = 'https://scores.explor.games/admin/moderation/' + match.params.experienceUid
            return null
          }}
        />
        <Route
          path={match.path + '/leaderboard'}
          component={() => {
            window.location.href = 'https://scores.explor.games/leaderboard/experience/' + match.params.experienceUid
            return null
          }}
        />

        <Route path={match.path + '/theme'} component={ExperienceTheme} />
        <Route path={match.path + '/themenewapp'} component={ExperienceThemeNewApp} />
        <Route path={match.path + '/deploy'} component={ExperienceDeploy} />
        <Route path={match.path + '/trash'} component={ExperienceTrash} />
        <Route path={match.path + '/texts'} component={ExperienceText} />
        <Route path={match.path + '/statistics'} component={Statistic} />
        <Route path={match.path + '/email'} component={ExperienceEmail} />
        <Route path={match.path + '/rating'} component={ExperienceRating} />
        <Route path={match.path + '/map/:mapUid?'} component={Map} />
        <Route path={match.path + '/track/:trackUid?'} component={Track} />
        <Route path={match.path + '/scenario/:scenarioUid?'} component={Scenario} />
        <Route path={match.path + '/segment/:segmentUid?'} component={Segment} />
        <Route path={match.path + '/timer/:timerUid?'} component={ExperienceTimer} />
        <Route path={match.path + '/tags'} component={ExperienceTags} />
        <Route path={match.path + '/tag/:tagUid?'} component={ExperienceTagForm} />

        <Route path={match.path + '/resources/'} component={ExperienceResources} />
        <Route path={match.path + '/characters/'} component={ExperienceCharacters} />
        <Route path={match.path + '/categories/'} component={ExperienceCategories} />
        <Route path={match.path + '/popups/'} component={Popups} />
        {/* <Route path={match.path + '/graph'} component={ExperienceGraph} /> */}
        <Route path={match.path + '/qrcodes'} component={ExperienceQrCodes} />
        <Route path={match.path + '/book/compare/:dest'} component={ExperienceCompare} />
        <Route path={match.path + '/book'} component={ExperienceBook} />
        <Route path={match.path + '/duplicate'} component={ExperienceDuplicate} />
        <Route path={match.path + '/voice'} component={ExperienceVoice} />
        <Route path={match.path + '/switch'} component={SwitchManager} />
        <Route path={match.path} component={ExperienceForm} />
      </Switch>
    )
  }
}
