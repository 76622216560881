import { toJS } from 'mobx'
import * as api from '../lib/api'

/** Enregistre les informations des switches de l'expérience. */
const expSwitches = { state: {} }

// Liste des types d'éléments modifiant des switchs. Entre parenthèse les ID nécessaires en plus de celui de l'élément et de l'expérience.
// type Type =
//  | 'experience'
//  | 'timer'
//  | 'track'
//  | 'point' (trackId)
//  | 'scenario'
//  | 'module' (scenarioId)

/** Initialisation du store, en récupérant toutes les informations nécessaires dans l'expérience. */
export const initSwitchStore = async (experienceId, noCache = false) => {
  try {
    if (!noCache) await new Promise(resolve => setTimeout(resolve, 1000)) // Pour laisser le temps au cache de se remplir.

    const [exp, tracks, points, scenarios, modules] = await Promise.all([
      api.experiences.get(experienceId, noCache),
      api.tracks.getFrom('experiences', experienceId, false, noCache),
      api.points.getFrom('experiences', experienceId, false, noCache),
      api.scenarios.getFrom('experiences', experienceId, false, noCache),
      api.modules.getFrom('experiences', experienceId, false, noCache)
    ])

    // { modules: { [key: string]: string[] }; actions: { [key: string]: { values: (string | number)[]; paths: string[] } } }
    const keys = { modules: {}, actions: {} }

    // Lecture des objets récupérés pour trouver toutes les utilisations de switchs.

    if (exp?.siteId && exp?._id) {
      // 1. Création d'un dictionnaire avec en clé les clés des switchs et en valeur les chemins vers les modules utilisant ces clés.
      if (modules?.length > 0) {
        modules
          .filter(m => m.type === 'switch')
          // Pour tous les modules Switch.
          .forEach(module => {
            if (module.scenarioUid && module.experienceUid && module.cases?.length > 0) {
              const path = `/site/${exp.siteId}/experience/${exp._id}/scenario/${module.scenarioUid}/module/${module._id}`
              // Pour chaque cas du module, les clés sont ajoutées à "keys.modules".
              module.cases.forEach(c => {
                if (!['isSolution', 'isNotSolution'].includes(c.method) && c.key) addModule(keys.modules, c.key, path)
                if (c.conditions?.length > 0) {
                  c.conditions.forEach(c => {
                    if (!['isSolution', 'isNotSolution'].includes(c.method) && c.key) addModule(keys.modules, c.key, path)
                  })
                }
              })
            }
          })
      }

      // 2. Création d'un dictionnaire avec en clé les clés des switchs, et en valeur les chemins vers les actions utilisant ces clés.
      // 2.1 Pour les actions de l'expérience.
      if (exp._id) {
        const path = `/site/${exp.siteId}/experience/${exp._id}`
        // Au démarrage.
        addActions(keys.actions, exp.onStart || [], `${path}#root_onStart`)

        // Timers.
        const timers = Object.values(exp.timers || {})
        if (timers.length > 0)
          timers.forEach(t => {
            if (t._id && t.actions?.length > 0) addActions(keys.actions, t.actions || [], `${path}/timer/${t._id}`)
          })
      }

      // 2.2 Pour les actions des parcours (déclencheurs et menus).
      if (tracks?.length > 0) {
        tracks.forEach(track => {
          const path = `/site/${exp.siteId}/experience/${exp._id}/track/${track._id}`

          // Dans les déclencheurs.
          if (track.events?.length)
            track.events.forEach(event => addActions(keys.actions, event.actions || [], `${path}#root_events`))

          // Dans les menus.
          if (track.menu?.length)
            track.menu.forEach(menu => menu.action && addAction(keys.actions, menu.action, `${path}#root_menu`))
        })
      }

      // 2.3 Pour les actions des points.
      if (points?.length > 0) {
        points.forEach(point => {
          if (point.trackId && point._id) {
            const path = `/site/${exp.siteId}/experience/${exp._id}/track/${point.trackId}/point/${point._id}/trigger`
            // Premières actions, et autre actions.
            addActions(keys.actions, point.firstActions || [], path)
            addActions(keys.actions, point.actions || [], path)
          }
        })
      }

      // 2.4 Pour les actions des scénarios (actions de fin).
      if (scenarios?.length > 0) {
        scenarios.forEach(scenario => {
          const path = `/site/${exp.siteId}/experience/${exp._id}/scenario/${scenario._id}#root_onEnd`
          addActions(keys.actions, scenario.onEnd || [], path)
        })
      }

      // 2.5 Pour les actions des modules (boutons d'action, onNext, et spécificités des modules).
      if (modules?.length > 0) {
        modules.forEach(m => {
          const module = toJS(m) // Pour retrouver un objet JS simple.
          const path = `/site/${exp.siteId}/experience/${exp._id}/scenario/${module.scenarioUid}/module/${module._id}`

          // onNext
          if (module.onNext?.length) {
            module.onNext.forEach(obj => addActions(keys.actions, obj.actions || [], `${path}#root_onNext`))
            delete module.onNext
          }

          // toolButton
          if (module.toolButton) {
            addAction(keys.actions, module.toolButton?.action || {}, `${path}#root_toolButton`)
            delete module.toolButton
          }

          // toolButtons
          if (module.listToolButtons?.length) {
            module.listToolButtons.forEach(b => addAction(keys.actions, b.action || {}, `${path}#root_listToolButtons`))
            delete module.listToolButtons
          }

          // Pour les actions spécifiques des modules, l'objet est parcouru en récursif à la recherche de clés spécifiques.
          findAndAddActions(keys.actions, module, ['actions', 'onFocus', 'onTouch', 'defaultActions'], path)
        })
      }
    }

    expSwitches.state = keys
  } catch (error) {
    console.log(error)
  }
}

/** Suppression des données en quittant l'expérience. */
export const resetSwitchStore = () => (expSwitches.state = {})

/** Retourne les chemins vers les utilisations d'une liste de clés de switch. */
export const getActionsPaths = (keys = []) => {
  const paths = []
  if (keys?.length > 0) keys.forEach(key => paths.push(...expSwitches.state.actions[key]))

  return [...new Set(paths)]
}

/** Retourne les chemins vers les modules switch utilisant une liste de clés. */
export const getModulesPaths = (keys = []) => {
  const paths = []
  if (keys?.length > 0) keys.forEach(key => paths.push(...expSwitches.state.modules[key]))

  return [...new Set(paths)]
}

const addModule = (dict = {}, key = '', path = '') => {
  if (!dict[key]) dict[key] = [path]
  // Création du tableau si la clé n'existe pas.
  else if (!dict[key].includes(path)) dict[key].push(path)
}

const addAction = (dict = {}, action = {}, path = '') => {
  if (['add_switch', 'delete_switch'].includes(action.type) && action.key) {
    if (!dict[action.key]) dict[action.key] = { paths: [], values: [] } // Création de l'objet si la clé n'existe pas.
    if (!dict[action.key].paths.includes(path)) dict[action.key].paths.push(path)
    if (action.value && !dict[action.key].values.includes(action.value)) dict[action.key].values.push(action.value)
  }
}

const addActions = (dict = {}, actions = [], path = '') => actions.forEach(action => addAction(dict, action, path))

/** Parcours un objet de façon récursive, pour trouver des actions ou liste d'action. */
const findAndAddActions = (dict = {}, obj, keys = [], path = '') => {
  // Pour chaque propriété de l'objet...
  for (let key in obj) {
    if (!obj.hasOwnProperty(key)) continue // Passe à la suite si ce n'est pas une propriété directe de l'objet.

    // La fonction effectue des traitement si la clé est un objet.
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      if (keys.includes(key)) addActions(dict, obj[key], path)
      else findAndAddActions(dict, obj[key], keys, path)
    }
  }
}
