/** Fonction vérifiant l'égalité entre deux objets de manière récursive. */
export const areEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) return false

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) return false

  for (let key of keys1) {
    if (!Object.prototype.hasOwnProperty.call(obj2, key)) return false

    if (!areEqual(obj1[key], obj2[key])) return false // Comparaison récursive
  }

  return true
}

export const normalize = (str = '') =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[\s\-_'\.,;:]+/g, '_') // eslint-disable-line
    .replace(/[^a-zA-Z0-9_]/g, '')
    .replace(/_+/g, '_')
    .replace(/^_|_$/g, '')
    .toLowerCase()

export const sortBy = (array = [], keys = ['name']) => {
  return [...array].sort((a, b) => {
    return normalize(a[keys.find(key => a?.[key])] || '').localeCompare(normalize(b[keys.find(key => b?.[key])] || ''))
  })
}
