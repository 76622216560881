import { user, conf, apiCache } from '../../stores'
import { toJS } from 'mobx'
/*
si cache mémoire ok => on retourne ça
sinon
    creer une collection temporaire t
    compteurGlobaleDeRequeteEnAttente ++
    faire la requete en sqlite
      .then
        SI la requete web n'est pas finie alors
          mettre les resultats dans t en les marquant comme "sale"
          les résultats "sales" affichent les form en readonly
    faire la requete web
      .then
        mettre les résultats dans t
        mettre les resultats dans le cache memoire
        mettre les résultats dans sqlite
        compteurGlobaleDeRequeteEnAttente --
    retourner t

*/
function list(serviceName, collectionName, showDeleted, noCache) {
  const cachedList = apiCache.list(serviceName, collectionName, showDeleted)
  if (!noCache && cachedList) {
    return Promise.resolve(cachedList)
  }

  return fetch(conf.API_URL + '/' + serviceName + '/' + collectionName, {
    headers: {
      Authorization: 'Bearer ' + user.token
    }
  })
    .then(res => res.json())
    .then(json => {
      if (json.code && json.msg) {
        if (json.code === 401) {
          user.isLoggued = false
          user.token = null
          return []
        }
        throw new Error(json.msg)
      }
      let clean = cleanNull(json)
      apiCache.createList(serviceName, collectionName, showDeleted, clean)
      clean = apiCache.list(serviceName, collectionName, showDeleted)
      return showDeleted ? clean : clean.filter(o => !o.deleted)
    })
}
function get(serviceName, collectionName, id, noCache) {
  const cached = apiCache.get(serviceName, collectionName, id)
  if (cached && !noCache) {
    return Promise.resolve(cached)
  }
  return fetch(conf.API_URL + '/' + serviceName + '/' + collectionName + '/' + id, {
    headers: {
      Authorization: 'Bearer ' + user.token
    }
  })
    .then(res => res.json())
    .then(json => {
      if (json.code && json.msg) {
        if (json.code === 401) {
          user.isLoggued = false
          user.token = null
          return {}
        }
        throw new Error(json.msg)
      }

      // json.uid = json._id
      const item = cleanNull(json)
      apiCache.upsert(serviceName, collectionName, id, item)
      return apiCache.get(serviceName, collectionName, id)
    })
}
function create(serviceName, collectionName, itemName, item) {
  console.log('service', serviceName)
  return fetch(conf.API_URL + '/' + serviceName + '/' + collectionName, {
    method: 'PUT',
    body: JSON.stringify({ [itemName]: toJS(item) }),
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + user.token
    }
  })
    .then(res => res.json())
    .then(json => {
      if (json.code && json.msg) {
        if (json.code === 401) {
          user.isLoggued = false
          user.token = null
          return {}
        }
        throw new Error(json.msg)
      }
      // json.uid = json._id
      const item = cleanNull(json[itemName])
      apiCache.upsert(serviceName, collectionName, item._id, item)
      return apiCache.get(serviceName, collectionName, item._id)
    })
}
function update(serviceName, collectionName, itemName, id, item) {
  const clone = { ...toJS(item) }
  delete clone.id
  delete clone.uid
  delete clone._id
  delete clone.__v
  return fetch(conf.API_URL + '/' + serviceName + '/' + collectionName + '/' + id, {
    method: 'POST',
    body: JSON.stringify({ [itemName]: clone }),
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + user.token
    }
  })
    .then(res => res.json())
    .then(json => {
      if (json.code && json.msg) {
        if (json.code === 401) {
          user.isLoggued = false
          user.token = null
          return {}
        }
        throw json.msg
      }
      // json.uid = json._id
      const item = cleanNull(json[itemName])
      apiCache.upsert(serviceName, collectionName, item._id, item)
      return apiCache.get(serviceName, collectionName, id)
    })
}
function cleanNull(item) {
  if (item === false) {
    return false
  }
  if (item === 0) {
    return 0
  }
  if (!item) {
    return null
  }
  if (Array.isArray(item)) {
    return item.map(cleanNull).filter(o => !!o)
  }
  if (typeof item === 'object') {
    const processed = {}
    for (var key in item) {
      const p = cleanNull(item[key])
      if (p !== null) {
        processed[key] = p
      }
    }
    return processed
  }
  return item
}
function emptyStringToNull(item) {
  if (item === false) {
    return false
  }
  if (item === 0) {
    return 0
  }
  if (!item) {
    return null
  }
  if (Array.isArray(item)) {
    return item.map(emptyStringToNull)
  }
  if (typeof item === 'object') {
    const processed = {}
    for (var key in item) {
      processed[key] = emptyStringToNull(item[key])
    }
    return processed
  }
  return item
}
function upsert(serviceName, collectionName, itemName, item) {
  const processed = emptyStringToNull(toJS(item))
  item._id = item._id || item.id || item.uid
  return !item._id
    ? create(serviceName, collectionName, itemName, processed)
    : update(serviceName, collectionName, itemName, item._id, processed)
}
function remove(serviceName, collectionName, id) {
  return fetch(conf.API_URL + '/' + serviceName + '/' + collectionName + '/' + id, {
    method: 'DELETE',
    headers: {
      Authorization: 'Bearer ' + user.token
    }
  }).then(() => {
    apiCache.remove(serviceName, collectionName, id)
  })
}

function listAvailableRollBack(serviceName, collectionName, id) {
  return fetch(conf.API_URL + '/' + serviceName + '/' + collectionName + '/' + id + '/history', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + user.token
    }
  })
    .then(res => res.json())
    .then(json => {
      if (json.code && json.msg) {
        throw new Error(json.msg)
      }
      return cleanNull(json)
    })
}
function rollBackToPatch(serviceName, collectionName, itemName, id, patchId) {
  return fetch(conf.API_URL + '/' + serviceName + '/' + collectionName + '/' + id + '/history', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + user.token
    },
    body: JSON.stringify({ patch: patchId })
  })
    .then(res => res.json())
    .then(json => {
      if (json.code && json.msg) {
        throw new Error(json.msg)
      }
      // json.uid = json._id
      return cleanNull(json[itemName])
    })
}
function rollBackToTime(serviceName, collectionName, itemName, id, timeStampSeconds) {
  return fetch(conf.API_URL + '/' + serviceName + '/' + collectionName + '/' + id, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + user.token
    },
    body: JSON.stringify({ time: timeStampSeconds })
  })
    .then(res => res.json())
    .then(json => {
      if (json.code && json.msg) {
        if (json.code === 401) {
          user.isLoggued = false
          user.token = null
          return {}
        }
        throw new Error(json.msg)
      }
      // json.uid = json._id
      return cleanNull(json[itemName])
    })
}

function getFrom(serviceName, collectionName, parentCollectionName, parentId, showDeleted, noCache) {
  const cached = apiCache.getFrom(serviceName, collectionName, parentCollectionName, parentId, showDeleted)
  if (cached && !noCache) {
    return Promise.resolve(cached)
  }

  return fetch(conf.API_URL + '/' + serviceName + '/' + parentCollectionName + '/' + parentId + '/' + collectionName, {
    headers: {
      Authorization: 'Bearer ' + user.token
    }
  })
    .then(res => res.json())
    .then(json => {
      if (json.code && json.msg) {
        if (json.code === 401) {
          user.isLoggued = false
          user.token = null
          return []
        }
        throw new Error(json.msg)
      }
      let items = cleanNull(json)
      apiCache.setFrom(serviceName, collectionName, parentCollectionName, parentId, showDeleted, items)
      items = apiCache.getFrom(serviceName, collectionName, parentCollectionName, parentId, showDeleted)
      return showDeleted ? items : items.filter(o => !o.deleted)
    })
}

export default function generify(serviceName, collectionName, itemName, opts) {
  const noCache = serviceName !== 'experiences-service'
  return {
    list: showDeleted => list(serviceName, collectionName, showDeleted, noCache),
    get: (id, noCacheOverride) =>
      get(serviceName, collectionName, id, typeof noCacheOverride === 'boolean' ? noCacheOverride : noCache),
    create: item => create(serviceName, collectionName, itemName, item),
    update: (id, item) => update(serviceName, collectionName, itemName, id, item),
    upsert: item => upsert(serviceName, collectionName, itemName, item),
    remove: id => remove(serviceName, collectionName, id),
    listAvailableRollBack: id => listAvailableRollBack(serviceName, collectionName, id),
    rollBackToPatch: (id, patchId) => rollBackToPatch(serviceName, collectionName, itemName, id, patchId),
    rollBackToTime: (id, timeStampSeconds) => rollBackToTime(serviceName, collectionName, itemName, id, timeStampSeconds),
    getFrom: (parentCollectioName, parentId, showDeleted, noCacheOverride) =>
      getFrom(
        serviceName,
        collectionName,
        parentCollectioName,
        parentId,
        showDeleted,
        typeof noCacheOverride === 'boolean' ? noCacheOverride : noCache
      )
  }
}
