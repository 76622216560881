import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Page } from '../'
import * as api from '../../lib/api'
import Loading from '../../components/loading'
import { NavInSites } from '../../components/nav'
import { apiCache } from '../../stores'

export const Bundles = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [bundles, setBundles] = useState(apiCache.bundles.list() || [])
  const [hovered, setHovered] = useState(undefined)

  useEffect(() => {
    api.bundles.list().then(bundles => {
      setBundles(bundles || [])
      setIsLoading(false)
    })
  }, [])

  return (
    <Page>
      <NavInSites />
      <div id="content">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {bundles.length === 0 ? (
              <p>Rien à afficher</p>
            ) : (
              <table className="table table-striped" style={{ marginBottom: 50 }}>
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Description</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {bundles.map(bundle => {
                    return (
                      <tr
                        key={bundle._id}
                        onMouseEnter={() => setHovered(bundle._id)}
                        onMouseLeave={() => setHovered(undefined)}
                        style={{ backgroundColor: hovered === bundle._id && '#f0f8ff' }}
                      >
                        <td>{bundle.name}</td>
                        <td>{bundle.description}</td>
                        <td>
                          <Link to={'/bundle/' + bundle._id} title="modifier">
                            <i className="fa fa-edit" />
                          </Link>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
            <div style={{ position: 'fixed', bottom: 5, right: 5 }}>
              <Link to="/bundle/" className="btn btn-primary btn-block">
                Ajouter un groupe
              </Link>
            </div>
          </>
        )}
      </div>
    </Page>
  )
}
