import React, { useEffect, useState } from 'react'

import * as api from '../../lib/api'

export const SiteCard = ({ site, onChange, removeSite, border }) => {
  const [hovered, setHovered] = useState(undefined)
  const [releases, setReleases] = useState([])

  // Requête pour trouver les expériences en prod.
  useEffect(() => {
    api.releases.listFromSite('prod', site.id).then(experiences => setReleases((experiences || []).map(exp => exp._id)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site?.id])

  const handleChange = (expIndex, exp) => {
    const experiences = site.experiences
    experiences[expIndex] = exp
    onChange({ ...site, experiences })
  }

  const allSelected = (site?.experiences || []).every(item => item.selected)
  const someSelected = (site?.experiences || []).some(item => item.selected)

  return (
    <>
      <tbody style={{ border: `1px solid ${border || (someSelected ? '#ccc' : '#e74c3c')}` }}>
        <tr>
          <td style={{ textAlign: 'center' }}>
            <label style={{ textAlign: 'center', padding: 4 }} htmlFor={`site-check-${site.id}`}>
              <input
                id={`site-check-${site.id}`}
                type="checkbox"
                checked={allSelected}
                ref={input => input && (input.indeterminate = someSelected && !allSelected)}
                onChange={() => {
                  onChange({
                    ...site,
                    experiences: site.experiences.map(exp => ({ ...exp, selected: someSelected ? false : true }))
                  })
                }}
              />
            </label>
          </td>
          <td style={{ ...styles.shrink, fontWeight: 'bold' }} colSpan={3}>
            {site.name}
          </td>
          <td style={{ textAlign: 'center', padding: 1 }}>
            <a className="btn btn-default" title="see" href={`/site/${site.id}`}>
              <i className="fa fa-eye"></i>
            </a>
          </td>
          <td style={{ textAlign: 'center', padding: 1 }}>
            {removeSite && (
              <button className="btn btn-danger array-item-remove" onClick={removeSite}>
                <i className="glyphicon glyphicon-remove"></i>
              </button>
            )}
          </td>
        </tr>
        {site.experiences.map((exp, expIndex) => (
          <tr
            key={exp.id}
            onMouseEnter={() => setHovered(expIndex)}
            onMouseLeave={() => setHovered(undefined)}
            style={{ backgroundColor: hovered === expIndex && '#f0f8ff' }}
          >
            <td style={{ textAlign: 'center' }}>
              <span style={{ fontSize: 10 }}>{releases.includes(exp.id) ? '🟢 ' : ''}</span>
            </td>
            <td style={{ textAlign: 'center' }}>
              <label style={{ textAlign: 'center', padding: 4 }} htmlFor={`exp-check-${exp.id}`}>
                <input
                  id={`exp-check-${exp.id}`}
                  type="checkbox"
                  checked={exp.selected}
                  onChange={() => handleChange(expIndex, { ...exp, selected: !exp.selected })}
                  style={{ marginRight: 10 }}
                />
              </label>
            </td>
            <td style={{ ...styles.shrink, paddingLeft: 5, opacity: exp.selected ? 1 : 0.7 }}>{exp.name}</td>
            <td style={{ textAlign: 'center' }}>
              <label style={{ textAlign: 'center', padding: 4 }} htmlFor={`doNotStart-${exp.id}`}>
                <input
                  id={`doNotStart-${exp.id}`}
                  type="checkbox"
                  checked={exp.doNotStart}
                  onChange={() => handleChange(expIndex, { ...exp, doNotStart: !exp.doNotStart })}
                  style={{ marginRight: 10, pointerEvents: exp.selected ? 'auto' : 'none' }}
                  disabled={!exp.selected}
                />
              </label>
            </td>
            <td style={{ textAlign: 'center', padding: 1 }}>
              <a className="btn btn-default" title="see" href={`/site/${site.id}/experience/${exp.id}`}>
                <i className="fa fa-eye"></i>
              </a>
            </td>
            <td></td>
          </tr>
        ))}
      </tbody>
      <tbody style={{ opacity: 0 }}>
        <tr>
          <td>#</td>
        </tr>
      </tbody>
    </>
  )
}

const styles = {
  shrink: { width: 'auto', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
}
