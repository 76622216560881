import { useEffect, useState } from 'react'
import { create } from 'zustand'

import { user, conf } from '../../stores'

let lastRequested = 0

export const useExtensionsStates = create(() => ({ isLoading: true, extensions: [] }))

export const fetchExtensions = async () => {
  useExtensionsStates.setState({ isLoading: true })
  return fetch(conf.API_URL + '/experiences-service/extensions')
    .then(res => res.json())
    .then(extensions => useExtensionsStates.setState({ extensions: sort(extensions || []), isLoading: false }))
    .finally(() => (lastRequested = Date.now()))
}

export const createExtension = extension =>
  fetch(conf.API_URL + '/experiences-service/extension', getFetchInit('POST', extension))
    .then(res => res.json())
    .then(json => {
      if (json.code && json.msg) throw new Error(json.msg)
      else useExtensionsStates.setState(state => ({ extensions: sort([...state.extensions, json]) }))
      return json
    })

export const updateExtension = (id, extension) =>
  fetch(conf.API_URL + '/experiences-service/extension/' + id, getFetchInit('PUT', extension))
    .then(res => res.json())
    .then(json => {
      if (json.code && json.msg) throw new Error(json.msg)
      else useExtensionsStates.setState(state => ({ extensions: sort(state.extensions.map(e => (e._id === id ? json : e))) }))
      return json
    })

export const deleteExtension = id =>
  fetch(conf.API_URL + '/experiences-service/extension/' + id, getFetchInit('DELETE'))
    .then(res => res.json())
    .then(json => {
      if (json.code && json.msg) throw new Error(json.msg)
      else useExtensionsStates.setState(state => ({ extensions: state.extensions.filter(e => e._id !== id) }))
      return json
    })

/** Hook permettant de récupérer la liste des extensions. */
export const useExtensions = (noCach = false) => {
  const [firstLoad, setFirsload] = useState(true)
  const extensions = useExtensionsStates(state => state.extensions)

  useEffect(() => {
    if (noCach || extensions.length === 0 || Date.now() - lastRequested > 5 * 60 * 1000) {
      fetchExtensions().then(() => setFirsload(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noCach])

  return firstLoad && noCach ? [] : extensions
}

/** Hook permettant de récupérer une extension. */
export const useExtension = (id, noCach = false) => {
  const [firstLoad, setFirsload] = useState(true)
  const extension = useExtensionsStates(state => state.extensions.find(e => e._id === id))

  useEffect(() => {
    if (noCach || useExtensionsStates.getState().extensions.length === 0 || Date.now() - lastRequested > 5 * 60 * 1000) {
      fetchExtensions().then(() => setFirsload(false))
    }
  }, [noCach])

  return firstLoad && noCach ? undefined : extension
}

const getFetchInit = (method = 'GET', body) => ({
  method,
  headers: { 'Content-Type': 'application/json', Authorization: user.token ? 'Bearer ' + user.token : '' },
  body: JSON.stringify(body || {})
})
const normalize = (str = '') => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^a-zA-Z0-9]/g, '').toLowerCase() // prettier-ignore
const sort = (array = [], key = 'name') => {
  return [...array].sort((a, b) => normalize(a?.[key] || '').localeCompare(normalize(b?.[key] || '')))
}
