import React, { Component } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { Site, Statistic } from '../'

import Application from './application'
import Applications from './applications'
import { NoMatch } from './noMatch'
import Sites from './sites'
import User from './user'
import { Users } from './users'
import Variant from './variant'
import { Bundles } from '../bundle/list'
import { BundleForm } from '../bundle/bundleForm'
import { ExtensionsList } from 'pages/extensions/extensionsList'
import { Extension } from 'pages/extensions/extension'

export default class RoutedSites extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Sites} />
          <Route path="/bundle/list" component={Bundles} />
          <Route path="/bundle/:bundleUid" component={BundleForm} />
          <Route path="/bundle" component={BundleForm} />
          <Route path="/site/:siteUid?" component={Site} />
          <Route path="/user/:userUid?" component={User} />
          <Route path="/users/" component={Users} />
          <Route path="/statistics" component={Statistic} />
          <Route path="/applications" component={Applications} />
          <Route path="/application/:applicationId/variant/:variantId?" component={Variant} />
          <Route path="/application/:applicationId?" component={Application} />
          <Route path="/extensions/" component={ExtensionsList} />
          <Route path="/extension/:extensionId?" component={Extension} />
          <Route path="*" component={NoMatch} />
        </Switch>
      </Router>
    )
  }
}
