import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import Loading from 'components/loading'
import { NavInSites } from 'components/nav'
import Page from 'pages/page'
import { useExtensions, useExtensionsStates } from 'lib/api/extensionsApi'

export const ExtensionsList = props => {
  const isLoading = useExtensionsStates(state => state.isLoading)
  const extensions = useExtensions()
  const [hovered, setHovered] = useState(undefined)

  return (
    <Page>
      <NavInSites />
      <div id="content" style={{ marginBottom: 30 }}>
        <h3>Liste des extensions</h3>
        <p style={{ marginBottom: 30 }}>
          Les extensions permettent d'ajouter des scénarios au début ou à la fin d'une expérience.
          <br />
          Ces scénarios n'apparaissent pas dans les paramètres de l'expérience sur le Workshop, mais sont ajoutés à la volée lors
          du téléchargement dans l'application.
        </p>

        {isLoading && extensions.length === 0 ? (
          <Loading />
        ) : (
          <>
            <table className="table table-striped" style={{ marginBottom: 50 }}>
              <thead>
                <tr>
                  <th style={{ width: 30 }}></th>
                  <th style={{ width: 200 }}>Nom</th>
                  <th style={{ minWidth: 200 }}>Description</th>
                  <th style={{ width: 50 }} />
                </tr>
              </thead>
              <tbody>
                {extensions.map(extension => {
                  return (
                    <tr
                      key={extension._id}
                      onMouseEnter={() => setHovered(extension._id)}
                      onMouseLeave={() => setHovered(undefined)}
                      style={{ cursor: 'pointer', backgroundColor: hovered === extension._id && '#f0f8ff' }}
                      onClick={() => props.history.push(`/extension/${extension._id}`)}
                    >
                      <td style={{ textAlign: 'center' }}>
                        <span role="img" aria-label={extension.published ? 'published' : 'not published'}>
                          {extension.published ? '🟢' : '🟡'}
                        </span>
                      </td>
                      <td>{extension.name}</td>
                      <td>{extension.description}</td>
                      <td style={{ color: '#207ce5' }}>
                        <i className="fa fa-edit" />
                      </td>
                    </tr>
                  )
                })}
                <tr>
                  <td colSpan={4}>{extensions.length === 0 && <div>Rien à afficher</div>}</td>
                </tr>
              </tbody>
            </table>

            <div style={{ position: 'fixed', bottom: 5, right: 5 }}>
              <Link to="/extension/" className="btn btn-primary btn-block">
                Ajouter une extension
              </Link>
            </div>
          </>
        )}
      </div>
    </Page>
  )
}
