import React, { useEffect, useState } from 'react'

import Loading from 'components/loading'
import { NavInSites } from 'components/nav'
import Page from 'pages/page'
import * as api from 'lib/api'
import { createExtension, deleteExtension, updateExtension, useExtension, useExtensionsStates } from 'lib/api/extensionsApi'
import { areEqual, normalize, sortBy } from 'utils/tools'
import { t } from 'stores/i18n.store'
import { notifications } from 'stores'
import { RawJsonForm } from 'components/rawJsonForm'
import { extensionCheck } from 'lib/check/extension.check'

export const Extension = props => {
  const extensionId = props?.match?.params?.extensionId || 'new'

  const extensionsLoading = useExtensionsStates(state => state.isLoading)
  const extension = useExtension(extensionId)

  const [formMode, setFormMode] = useState('form')
  const [isLoading, setIsLoading] = useState(true)
  const [state, setState] = useState() // Données locales de l'extension.
  const [errors, setErrors] = useState({})
  const [sitesOtp, setSitesOpt] = useState([])
  const [site, setSite] = useState()
  const [scenarios, setScenarios] = useState([])
  const [editing, setEditing] = useState(false)
  const [search, setSearch] = useState('')
  const [checks, setChecks] = useState()

  const [needUpdate, setNeedUpdate] = useState(false)

  // Récupération des sites et expériences au chargement du composant.
  useEffect(() => {
    Promise.all([api.sites.listActiveLite(), api.experiences.listActiveLite()])
      .then(([sites, experiences]) => {
        const sitesOtp = (sites || []).map(
          site => ({
            _id: site._id,
            name: site.name,
            experiences: sortBy((experiences || []).filter(exp => exp.siteId === site._id))
          }),
          {}
        )
        setSitesOpt(sortBy(sitesOtp))
      })
      .finally(() => setIsLoading(false))
  }, [])

  // Exécution des vérifications au chargement puis à chaque enregistrement de l'extension.
  useEffect(() => {
    if (extension) extensionCheck(extension).then(checks => setChecks(checks))
  }, [extension])

  // Mise à jour du site sélectionné à chaque modification de l'extension ou de la liste des sites connus.
  useEffect(() => {
    if (extension) setSite(sitesOtp.find(s => s.experiences.find(exp => exp._id === extension.experienceId)))
  }, [extension, sitesOtp])

  // À chaque modification de l'expérience, on récupère les scénarios.
  useEffect(() => {
    if (scenarios.length > 0) setScenarios([])
    if (state?.experienceId) {
      api.scenarios.getFromExperience(state.experienceId, false).then(scenarios => setScenarios(scenarios || []))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.experienceId])

  // Récupération des scénarios à chaque modification de l'ID de l'expérience.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {}, [state?.experienceId])

  // Met à jour le state avec les données de l'extension. En général c'est à chaque enregsitrement, ce qui modifie "needUpdate".
  useEffect(() => {
    if (extension) setState({ ...(extension || {}) })
  }, [extension])

  // Vérifie si les données ont été modifiées.
  useEffect(() => {
    const equal = areEqual(state, extension)
    if (needUpdate === equal) setNeedUpdate(!equal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, extension])

  // Affiche un message en cas de tentative de quitter la page si des données ont été modifiées.
  useEffect(() => {
    const onExit = event => needUpdate && (event.returnValue = t('unsaved'))
    const unblock = props.history.block(location => {
      if (needUpdate) {
        if (window.confirm(t('unsaved'))) {
          unblock()
          props.history.push(location.pathname)
        } else return false
      }
    })
    window.addEventListener('beforeunload', onExit)
    return () => void (window.removeEventListener('beforeunload', onExit), unblock())
  }, [needUpdate, props.history])

  const onDelete = async () => {
    if (extension?._id && window.confirm(t('alert-delete'))) {
      await deleteExtension(extension?._id)
      if (props?.history?.push) props.history.push('/extensions')
    }
  }

  const handleSubmit = event => {
    event.preventDefault()

    const validationErrors = {}
    if (!state.name) validationErrors.name = 'Le nom est obligatoire.'
    if (!state.experienceId) validationErrors.experienceId = "L'ID de l'expérience est obligatoire."
    if (!state.scenarioId) validationErrors.scenarioId = 'L’ID du scénario est obligatoire.'

    setErrors(validationErrors)

    if (Object.keys(validationErrors).length === 0) {
      if (
        state?.experiences?.length > 0 && // S'il y a des expériences configuées...
        state.published && // Que l'extension est publiée...
        (!extension?.published || // Et que soit elle ne l'était déjà...
          (state?.experiences?.length >= (extension?.experiences?.length || 0) && // Soit il y plus ou autant d'expériences que précédemment...
            !areEqual(state?.experiences || [], extension?.experiences || []))) && // Et que les expériences ont changées...
        !window.confirm(t('alert-extension-publish')) // Alors on affiche une alerte avec demande de confirmation.
      ) {
        return // Si la demande est refusée, la fonction s'arrête.
      }

      const id = Date.now()
      const promise = extensionId === 'new' ? createExtension({ ...state }) : updateExtension(extensionId, { ...state })
      promise
        .then(extension => {
          setEditing(false)
          setSearch('')
          notifications.set(id, { id, type: 'success', title: t('save-success'), autoClose: 1500 })

          if (extensionId === 'new' && extension?._id && props?.history?.push) {
            setNeedUpdate(false)
            setTimeout(() => props.history.push(`/extension/${extension._id}`), 100)
          }
        })
        .catch(error => {
          notifications.set(id, { id, type: 'danger', title: t('save-error'), content: error.toString(), autoClose: 2500 })
        })
    }
  }

  return (
    <Page>
      <NavInSites />
      <div id="content" style={{ marginBottom: 30 }}>
        {extensionsLoading || isLoading ? (
          <Loading />
        ) : (
          <>
            {!extension?._id && extensionId !== 'new' ? (
              <div>Extension non trouvée...</div>
            ) : (
              <>
                {checks?.errors.map((e, i) => (
                  <p className="bg-danger" key={i}>
                    {e.text}
                  </p>
                ))}
                {checks?.warnings.map((e, i) => (
                  <p className="bg-warning" key={i}>
                    {e.text}
                  </p>
                ))}
                {checks?.nb > 0 && (
                  <p className="bg-success">{checks.nb - checks.warnings.length - checks.errors.length} vérifications réussies</p>
                )}

                <div style={{ marginBottom: 20, display: 'flex' }}>
                  <div className="btn-group" role="group" style={{ flex: 1 }}>
                    <button
                      className={`btn ${formMode === 'json' ? 'btn-info' : 'btn-default'}`}
                      onClick={() => setFormMode('json')}
                    >
                      {t('mode-json')}
                    </button>
                    <button
                      className={`btn ${formMode === 'form' ? 'btn-info' : 'btn-default'}`}
                      onClick={() => setFormMode('form')}
                    >
                      {t('mode-form')}
                    </button>
                  </div>

                  {extension?._id && (
                    <button className="btn btn-danger" onClick={onDelete}>
                      <i className="fa fa-trash"></i>
                    </button>
                  )}
                </div>

                {formMode === 'json' ? (
                  <RawJsonForm
                    value={state || {}}
                    onChange={state => setState(state)}
                    onSubmit={() => handleSubmit({ preventDefault: () => {} })}
                  />
                ) : (
                  <>
                    <h3>Extension</h3>

                    <form onSubmit={handleSubmit}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <label style={{ textAlign: 'center', padding: 4, marginRight: 5 }} htmlFor="published">
                          <input
                            id="published"
                            type="checkbox"
                            checked={!!state?.published}
                            onChange={() => setState({ ...state, published: !state?.published })}
                          />
                        </label>
                        <label htmlFor="published">Publiée</label>
                      </div>
                      <p style={{ marginBottom: 30 }}>
                        <span role="img" aria-label="warning">
                          ⚠️
                        </span>{' '}
                        Une fois l'extension publiée, elle sera instantanément appliquée à toutes les expériences configuée, sur
                        tous les environnements.
                      </p>

                      <div className="form-group">
                        <label htmlFor="name">Nom</label>
                        <input
                          id="name"
                          type="text"
                          className="form-control"
                          value={state?.name || ''}
                          onChange={e => setState({ ...state, name: e.target.value || '' })}
                          style={errors.name && { backgroundColor: '#e74c3c55' }}
                        />
                        {errors.name && <small className="text-danger">{errors.name}</small>}
                      </div>

                      <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <textarea
                          id="description"
                          className="form-control"
                          rows="2"
                          value={state?.description || ''}
                          onChange={e => setState({ ...state, description: e.target.value || '' })}
                          style={errors.description && { backgroundColor: '#e74c3c55' }}
                        />
                      </div>
                      <div className="form-group">
                        <label>Cible</label>
                        <p>
                          Endroit où est insérée l'extension. Elle peut se placer au tout début de l'expérience (onStart), ou
                          juste avant la ou les actions de fin de partie.
                        </p>
                        <select
                          className="form-control"
                          style={{ width: 'auto' }}
                          value={state?.target || 'start'}
                          onChange={e => {
                            const target = e.target.value || 'start'
                            setState(state => ({ ...state, target }))
                          }}
                        >
                          <option value={'start'}>Lancément de l'expérience</option>
                          <option value={'end'}>Fin de l'expérience</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="order">Ordre</label>
                        <p>
                          Si plusieurs extensions sont appliquées à une expérience, les extensions avec l'ordre le plus bas
                          s'exécutent en premier.
                        </p>
                        <input
                          style={{ width: 'auto' }}
                          type="number"
                          id="order"
                          name="order"
                          className="form-control"
                          value={state?.order || 0}
                          onChange={e => setState({ ...state, order: parseInt(e.target.value || '0') })}
                        />
                      </div>

                      <div
                        style={{
                          borderLeft: '1px solid #499bea',
                          padding: 10,
                          marginTop: 20,
                          marginBottom: 20,
                          overflow: 'hidden'
                        }}
                      >
                        <p>
                          Configuration du scénario à appliquer. On a besoin d'un site et d'une expérience pour le sélectionner.
                        </p>
                        <div className="form-group">
                          <label>Site</label>
                          <select
                            className="form-control"
                            style={{ width: 'auto', maxWidth: '95%', minWidth: 250 }}
                            value={site?._id}
                            onChange={e => {
                              const site = sitesOtp.find(s => s._id === e.target.value)
                              setState(state => ({ ...state, experienceId: null, scenarioId: null }))
                              setSite(site)
                            }}
                          >
                            <option value={undefined}></option>
                            {sitesOtp.map(site => (
                              <option value={site._id} key={site._id}>
                                {site.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="form-group">
                          <label>
                            Expérience
                            <a
                              className="btn btn-default"
                              title="see"
                              style={{ marginLeft: 10 }}
                              {...(site?._id && state?.experienceId
                                ? { href: `/site/${site._id}/experience/${state.experienceId}` }
                                : { disabled: true })}
                            >
                              Voir
                            </a>
                          </label>
                          <select
                            className="form-control"
                            style={{
                              width: 'auto',
                              maxWidth: '95%',
                              minWidth: 250,
                              ...(errors.experienceId && { backgroundColor: '#e74c3c55' })
                            }}
                            value={state?.experienceId || undefined}
                            onChange={e => {
                              const expId = e.target.value || null
                              setState(state => ({ ...state, experienceId: expId, scenarioId: null }))
                            }}
                          >
                            <option value={undefined}></option>
                            {(site?.experiences || []).map(exp => (
                              <option value={exp._id} key={exp._id}>
                                {`${exp.name || exp._id}${exp.locale ? ` (${exp.locale})` : ''}`}
                              </option>
                            ))}
                          </select>
                          {errors.experienceId && <small className="text-danger">{errors.experienceId}</small>}
                        </div>

                        <div className="form-group">
                          <label>
                            Scénario
                            <a
                              className="btn btn-default"
                              title="see"
                              style={{ marginLeft: 10 }}
                              {...(site?._id && state?.experienceId && state?.scenarioId
                                ? { href: `/site/${site?._id}/experience/${state?.experienceId}/scenario/${state?.scenarioId}` }
                                : { disabled: true })}
                            >
                              Voir
                            </a>
                          </label>
                          <select
                            className="form-control"
                            style={{
                              width: 'auto',
                              maxWidth: '95%',
                              minWidth: 250,
                              ...(errors.scenarioId && { backgroundColor: '#e74c3c55' })
                            }}
                            value={state?.scenarioId || undefined}
                            onChange={e => {
                              const scenarioId = e.target.value || null
                              setState(state => ({ ...state, scenarioId }))
                            }}
                          >
                            <option value={undefined}></option>
                            {(scenarios || []).map(scenario => (
                              <option value={scenario._id} key={scenario._id}>
                                {scenario.name || scenario._id}
                              </option>
                            ))}
                          </select>
                          {errors.scenarioId && <small className="text-danger">{errors.scenarioId}</small>}
                        </div>
                      </div>

                      <div style={{ overflow: 'auto' }}>
                        <table style={{ tableLayout: 'fixed', width: '100%' }}>
                          <thead style={{ borderBottom: '1px solid #ddd' }}>
                            <tr style={{ opacity: 0 }}>
                              <th style={{ width: 40 }}>#</th>
                              <th style={{ width: 'auto' }}>#</th>
                              <th style={{ width: 40 }}>#</th>
                            </tr>
                            <tr>
                              <th colSpan={2} style={{ paddingTop: 15, paddingBottom: 15 }}>
                                {editing ? 'Sélectionnez des expériences' : 'Expériences configurées'}
                              </th>
                              <th style={{ textAlign: 'center' }}>
                                <div className="btn btn-info" onClick={() => setEditing(!editing)}>
                                  <i className={`fa ${editing ? 'fa-check' : 'fa-edit'}`} />
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {editing && (
                              <>
                                <tr>
                                  <td colSpan={3}>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={search || ''}
                                      placeholder="Rechercher..."
                                      onChange={e => setSearch(e.target.value || '')}
                                    />
                                  </td>
                                </tr>
                              </>
                            )}
                            <tr style={{ opacity: 0 }}>
                              <td>#</td>
                            </tr>
                          </tbody>
                          {sitesOtp
                            .filter(site =>
                              editing && search !== ''
                                ? normalize(site.name).includes(normalize(search)) ||
                                  site.experiences.find(exp => normalize(exp.name).includes(normalize(search)))
                                : editing || site.experiences.find(exp => state?.experiences?.includes(exp._id))
                            )
                            .map(site => (
                              <tbody key={site._id}>
                                <tr>
                                  <td colSpan={2} style={{ ...styles.shrink, opacity: 0.8, fontWeight: 'bold' }}>
                                    {site.name}
                                  </td>
                                </tr>
                                {(site?.experiences || [])
                                  .filter(exp =>
                                    editing && search !== ''
                                      ? normalize(site.name).includes(normalize(search)) ||
                                        normalize(exp.name).includes(normalize(search))
                                      : editing || state.experiences?.includes(exp._id)
                                  )
                                  .map(exp => (
                                    <tr key={exp._id}>
                                      <td style={{ ...styles.shrink }}>
                                        <label style={{ textAlign: 'center', padding: 4 }} htmlFor={exp._id}>
                                          <input
                                            id={exp._id}
                                            type="checkbox"
                                            checked={!!state?.experiences?.includes(exp._id)}
                                            onChange={() => {
                                              setState(state => {
                                                const experiences = state.experiences?.includes(exp._id)
                                                  ? state.experiences.filter(id => id !== exp._id)
                                                  : [...(state.experiences || []), exp._id]
                                                return { ...state, experiences }
                                              })
                                            }}
                                            disabled={!editing}
                                          />
                                        </label>
                                      </td>
                                      <td colSpan={2} style={{ ...styles.shrink }}>{`${exp.name}${
                                        exp.locale ? ` (${exp.locale})` : ''
                                      }`}</td>
                                    </tr>
                                  ))}
                                <tr style={{ opacity: 0 }}>
                                  <td>#</td>
                                </tr>
                              </tbody>
                            ))}
                        </table>
                      </div>

                      <div
                        style={{
                          position: 'fixed',
                          bottom: 5,
                          right: 5,
                          ...(!needUpdate && { opacity: 0.6, pointerEvents: 'none' })
                        }}
                      >
                        <button type="submit" className="btn btn-primary">
                          Enregistrer l'extension
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Page>
  )
}

const styles = {
  shrink: { width: 'auto', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
}
