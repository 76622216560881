import React, { useState } from 'react'

export const RawJsonForm = ({ value, onChange, onSubmit }) => {
  const [localValue, setValue] = useState(JSON.stringify(value, null, 2).replace(/\\n/g, '\n'))
  const [valid, setValid] = useState(true)

  const handleChange = e => {
    const newValue = e.target.value
    setValue(e.target.value)
    const json = toJson(newValue)
    const isValid = !!json
    setValid(isValid)
    if (isValid) {
      setValid(true)
      onChange && onChange(json)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    e.stopPropagation()
    onSubmit && onSubmit(toJson(localValue))
  }

  return (
    <div style={{ height: '80%', minHeight: 500, display: 'flex', flexDirection: 'column', marginBottom: 25 }}>
      <textarea
        className="form-control"
        style={{ flex: 1, border: `1px solid ${valid ? '#0000' : '#e74c3c'}`, resize: 'none' }}
        value={localValue}
        onChange={handleChange}
      />
      <div>
        {valid ? (
          <input type="button" value="Enregistrer" className="btn btn-primary btn-block" onClick={handleSubmit} />
        ) : (
          <div style={{ textAlign: 'center', fontWeight: 'bold', color: '#e74c3c', padding: 6 }}>Le json n'est pas valide</div>
        )}
      </div>
    </div>
  )
}

const toJson = val => {
  const l = val.length
  let inString = false
  let precIsSlash = false
  let res = ''

  for (let i = 0; i < l; i++) {
    if (val[i] === '\\' && !precIsSlash) {
      precIsSlash = true
      res += val[i]
      continue
    }
    if (val[i] === '"' && !precIsSlash) {
      inString = !inString
      res += val[i]
      continue
    }
    if (val[i] === '\n' && inString) {
      res += '\\n'
      continue
    }
    res += val[i]
    precIsSlash = false
  }

  try {
    const json = JSON.parse(res)
    return json
  } catch (e) {
    return false
  }
}
