import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Page } from '../'
import * as api from '../../lib/api'

import Loading from '../../components/loading'
import { NavInSites } from '../../components/nav'
import { apiCache } from 'stores'

const leveltToText = { owner: 'Créateur ', viewer: 'Lecteur ', editor: 'Editeur ' }
const typeToText = { bundles: "du groupe d'expériences ", sites: 'du site ', experiences: "de l'expérience " }

export const Users = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [states, setStates] = useState({
    users: sortBy(apiCache.users.list() || [], ['lastname', 'firstname']),
    sites: [],
    experiences: [],
    bundles: []
  })
  const [hovered, setHovered] = useState(undefined)

  useEffect(() => {
    Promise.all([api.users.list(), api.sites.list(), api.experiences.list(), api.bundles.list()]).then(
      ([users, sites, experiences, bundles]) => {
        // Modification des states.
        setStates({
          users: sortBy(users || [], ['lastname', 'firstname']),
          sites: sites || [],
          experiences: experiences || [],
          bundles: bundles || []
        })
        setIsLoading(false)
      }
    )
  }, [])

  return (
    <Page>
      <NavInSites />
      <div id="content">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <table className="table table-striped" style={{ marginBottom: 50 }}>
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Email</th>
                  <th>Autorisations</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {states.users.map(user => (
                  <tr
                    key={user._id}
                    onMouseEnter={() => setHovered(user._id)}
                    onMouseLeave={() => setHovered(undefined)}
                    style={{ backgroundColor: hovered === user._id && '#f0f8ff' }}
                  >
                    <td>
                      {user.lastname} {user.firstname}
                    </td>
                    <td>{user.email}</td>
                    <td>
                      {user.scopes.map(scope => {
                        if (scope === 'admin') return 'administrateur'

                        const [type, id, level] = scope.split('/')
                        let scopeText = leveltToText[level] + typeToText[type]

                        const o = states[type].find(one => one._id === id) || {}
                        scopeText += '"' + (o && o.name) + '" '
                        return (
                          <span key={scope}>
                            {scopeText} <br />
                          </span>
                        )
                      })}
                    </td>
                    <td>
                      <Link to={'/user/' + user._id} title="modifier">
                        <i className="fa fa-edit" />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div style={{ position: 'fixed', bottom: 5, right: 5 }}>
              <Link to="/user/" className="btn btn-primary btn-block">
                Ajouter un utilisateur
              </Link>
            </div>
          </>
        )}
      </div>
      <div id="app-preview" />
    </Page>
  )
}

const normalize = (str = '') => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^a-zA-Z0-9]/g, '').toLowerCase() // prettier-ignore
const sortBy = (array = [], keys = ['name']) => {
  return [...array].sort((a, b) => {
    return normalize(a[keys.find(key => a?.[key])] || '').localeCompare(normalize(b[keys.find(key => b?.[key])] || ''))
  })
}
