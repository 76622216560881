import generify from './generic'
import { user, conf, apiCache } from '../../stores'

const cache = { lastRequested: 0, list: [] }

export const experiences = generify('experiences-service', 'experiences', 'experience')

experiences.listActiveLite = async () => {
  // Si les expériences existent dans le cache normal, le cache local est mis à jour.
  if (apiCache.experiences.list()?.length > 0) {
    const newExps = apiCache.experiences.list().filter(exp => exp.deleted !== true).map(exp => ({ _id: exp._id, name: exp.name, siteId: exp.siteId, locale: exp.locale })) // prettier-ignore
    cache.list = newExps || []
    cache.lastRequested = Date.now()
  }

  // Si on a du cache local récent, pas besoin de faire la requête.
  if (cache.list?.length > 0 && Date.now() - cache.lastRequested < 5 * 60 * 1000) {
    return Promise.resolve(cache.list)
  } else {
    return fetch(
      conf.API_URL + '/experiences-service/experiences?select=_id,name,siteId,locale&filter={"deleted":{"$ne":true}}',
      { headers: { Authorization: 'Bearer ' + user.token }, method: 'GET' }
    )
      .then(res => res.json())
      .then(json => {
        if (json.code && json.msg) throw json.msg
        cache.list = json || []
        cache.lastRequested = Date.now()
        return json
      })
  }
}

experiences.getFromSite = (siteUid, showDeleted) => {
  return experiences.getFrom('sites', siteUid, showDeleted)
}

experiences.deploy = (environnment, experienceUid) => {
  return fetch(conf.API_URL + '/experiences-service/experiences/' + experienceUid + '/publish/' + environnment, {
    headers: {
      Authorization: 'Bearer ' + user.token
    },
    method: 'POST'
  })
    .then(res => res.json())
    .then(json => {
      if (json.code && json.msg) {
        throw json.msg
      }
      return json
    })
}

experiences.undeploy = (environment, experienceUid) => {
  return fetch(conf.API_URL + '/experiences-service/releases/' + environment + '/experience/' + experienceUid, {
    headers: {
      Authorization: 'Bearer ' + user.token
    },
    method: 'DELETE'
  })
    .then(res => res.json())
    .then(json => {
      if (json.code && json.msg) {
        throw json.msg
      }
      return json
    })
}

experiences.getMessagingChannel = experienceUid => {
  return Promise.resolve() // La route "messaging" ne fonctionne plus.
  // return fetch(conf.API_URL + '/experiences-service/experiences/' + experienceUid + '/messaging', {
  //   headers: { Authorization: 'Bearer ' + user.token },
  //   method: 'GET'
  // })
  //   .then(res => res.json())
  //   .then(json => {
  //     return json.session
  //   })
}

experiences.duplicate = (experienceUid, siteId) => {
  return fetch(conf.API_URL + '/experiences-service/experiences/' + experienceUid + '/duplicate', {
    method: 'POST',
    body: JSON.stringify({ siteId }),
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + user.token
    }
  })
    .then(res => res.json())
    .then(json => {
      if (json.code && json.msg) {
        if (json.code === '401') {
          user.isLoggued = false
          user.token = null
        }
        throw json.msg
      }
      // json.uid = json._id
      return json.experience
    })
}

experiences.export = (experienceUid, progress) => {
  const url = conf.API_URL + '/experiences-service/experiences/' + experienceUid + '/export'
  const headers = {
    Authorization: 'Bearer ' + user.token
  }
  progress = progress || function() {}
  const xhr = new XMLHttpRequest()
  var promise = new Promise(function(resolve, reject) {
    xhr.addEventListener('readystatechange', e => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        resolve(xhr.response)
      }
    })
    xhr.addEventListener('progress', e => {
      progress(e.loaded)
    })

    xhr.open('GET', url, true)
    Object.keys(headers || {}).forEach(header => {
      xhr.setRequestHeader(header, headers[header])
    })
    xhr.responseType = 'blob'
    xhr.send()
  })

  return promise
}

experiences.unprocessed = experienceUid => {
  return fetch(conf.API_URL + '/experiences-service/experiences/' + experienceUid + '/resources/unprocessed', {
    method: 'GET',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + user.token
    }
  }).then(res => res.json())
}
