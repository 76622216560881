import React, { useEffect, useRef, useState } from 'react'
import { areEqual } from 'utils/tools'

// Inspiré de FlatList en React Native.
export const DraggableList = ({
  data,
  RenderItem,
  onReorder,
  horizontal = false,
  containerStyle = {},
  itemContainerStyle = {},
  backgroundColor = '#FFF',
  dropColor = '#7fb3d5',
  spacing = 4
}) => {
  const containerRef = useRef(null)
  const itemsRef = useRef(data)
  const itemRefs = useRef(new Map())

  const keys = useRef((data || []).map(() => crypto.randomUUID()))

  const [draggedIndex, setDraggedIndex] = useState(null)
  const [hoveredIndex, setHoveredIndex] = useState(null)
  const [hoverPosition, setHoverPosition] = useState(null)

  useEffect(() => void (itemsRef.current = data), [data])

  // Suppression des clés inutilisées (l'ajout se fait à la demande dans le rendu.).
  useEffect(() => void (keys.current.length > data.length && (keys.current.length = data.length)), [data.length])

  const move = (data, from, to) => {
    const newData = [...data]
    const newKeys = [...keys.current]
    const [element] = newData.splice(to, 1)
    const [key] = newKeys.splice(to, 1)
    newData.splice(from, 0, element)
    newKeys.splice(from, 0, key)

    keys.current = newKeys
    onReorder(newData)
  }

  const handleDragStart = (e, index) => {
    setDraggedIndex(index)
    const draggedElement = e.currentTarget.parentNode // Le container entier du RenderItem
    if (e.dataTransfer) {
      e.dataTransfer.effectAllowed = 'move'
      if (draggedElement) e.dataTransfer.setDragImage(draggedElement, 0, 0)
    }
  }

  const handleDragOver = (e, index) => {
    e.preventDefault()

    setHoveredIndex(index)
    const bounding = e.currentTarget.getBoundingClientRect()
    if (horizontal) {
      setHoverPosition(e.clientX - bounding.left < bounding.width / 2 ? 'before' : 'after')
    } else {
      setHoverPosition(e.clientY - bounding.top < bounding.height / 2 ? 'before' : 'after')
    }
  }

  const handleDrop = (e, dropedIndex) => {
    e.preventDefault()
    const newIndex = dropedIndex - (hoverPosition === 'before' ? 1 : 0) + (dropedIndex < draggedIndex ? 1 : 0)
    if (dropedIndex !== draggedIndex && draggedIndex !== newIndex) {
      move(data, draggedIndex, newIndex)

      setTimeout(
        () => scrollIfNeeded(itemRefs.current.get(data[draggedIndex].id)?.current, containerRef.current, horizontal, spacing),
        0
      )
    }

    setHoveredIndex(null)
    setDraggedIndex(null)
    setHoverPosition(null)
  }

  const handleTouchMove = e => {
    const touch = e.touches[0]

    const element = document.elementFromPoint(touch.clientX, touch.clientY)
    if (!element) return

    const hoverElement = element.closest('[data-index]')
    if (hoverElement) {
      const index = parseInt(hoverElement.getAttribute('data-index'), 10)
      setHoveredIndex(index)

      const bounding = hoverElement.getBoundingClientRect()

      if (horizontal) {
        setHoverPosition(touch.clientX - bounding.left < bounding.width / 2 ? 'before' : 'after')
      } else {
        setHoverPosition(touch.clientY - bounding.top < bounding.height / 2 ? 'before' : 'after')
      }
    }
  }

  const handleTouchEnd = () => {
    if (hoveredIndex !== null && draggedIndex !== null && hoverPosition !== null) {
      const newIndex = hoveredIndex - (hoverPosition === 'before' ? 1 : 0) + (hoveredIndex < draggedIndex ? 1 : 0)

      if (hoveredIndex !== draggedIndex && draggedIndex !== newIndex) {
        move(data, draggedIndex, newIndex)
        setTimeout(
          () => scrollIfNeeded(itemRefs.current.get(data[draggedIndex].id)?.current, containerRef.current, horizontal, spacing),
          0
        )
      }
    }

    setHoveredIndex(null)
    setDraggedIndex(null)
    setHoverPosition(null)
  }

  const MemoizedRenderItem = useRef(
    React.memo(
      RenderItem,
      (prevProps, nextProps) => areEqual(prevProps.item, nextProps.item) && prevProps.index === nextProps.index
    )
  )

  return (
    <div
      style={{
        ...containerStyle,
        ...{ display: 'flex', flexDirection: horizontal ? 'row' : 'column', gap: spacing, padding: spacing, overflow: 'auto' }
      }}
      ref={containerRef}
    >
      {data.map((item, index) => {
        if (!keys.current[index]) keys.current[index] = crypto.randomUUID()
        const key = keys.current[index]

        if (!itemRefs.current.has(key)) itemRefs.current.set(key, React.createRef())
        const itemRef = itemRefs.current.get(key)

        return (
          <div
            ref={itemRef}
            data-index={index}
            key={key}
            style={{
              width: itemContainerStyle?.width || (horizontal ? 'auto' : '100%'),
              height: itemContainerStyle?.height || 'auto',
              display: 'flex',
              flexDirection: horizontal ? 'row' : 'column',
              position: 'relative',
              opacity: draggedIndex === index ? 0.7 : 1
            }}
            onDragOver={e => handleDragOver(e, index)}
            onDrop={e => handleDrop(e, index)}
          >
            <div
              style={{
                backgroundColor,
                padding: spacing,
                borderRadius: spacing,
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                height: 'auto',
                overflow: 'hidden',
                ...itemContainerStyle
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 0 }}>
                <div
                  className="btn btn-default"
                  style={{ cursor: 'grab', touchAction: 'none', ...(data.length === 1 && { opacity: 0, pointerEvents: 'none' }) }}
                  draggable
                  onDragStart={e => handleDragStart(e, index)}
                  onDragEnd={() => {
                    setHoveredIndex(null)
                    setDraggedIndex(null)
                    setHoverPosition(null)
                  }}
                  onTouchStart={e => handleDragStart(e, index)}
                  onTouchMove={e => handleTouchMove(e)}
                  onTouchEnd={handleTouchEnd}
                >
                  ⋮⋮
                </div>

                <div className="btn-group">
                  {data.length > 1 && (
                    <>
                      <button
                        className="btn btn-default"
                        onClick={e => {
                          e.preventDefault()
                          if (index === 0) return
                          move(data, index, index - 1)

                          setTimeout(() => {
                            const itemElement = itemRefs.current.get(item.id)?.current
                            scrollIfNeeded(itemElement, containerRef.current, horizontal, spacing)
                          }, 0)
                        }}
                        style={{ opacity: index === 0 ? 0.5 : 1 }}
                      >
                        <i className={`fa fa-arrow-${horizontal ? 'left' : 'up'}`} />
                      </button>
                      <button
                        className="btn btn-default"
                        onClick={e => {
                          e.preventDefault()
                          if (index === data.length - 1) return
                          move(data, index, index + 1)

                          setTimeout(() => {
                            const itemElement = itemRefs.current.get(item.id)?.current
                            scrollIfNeeded(itemElement, containerRef.current, horizontal, spacing)
                          }, 0)
                        }}
                        style={{ opacity: index === data.length - 1 ? 0.5 : 1 }}
                      >
                        <i className={`fa fa-arrow-${horizontal ? 'right' : 'down'}`} />
                      </button>
                    </>
                  )}

                  <button
                    className="btn btn-danger"
                    style={{ fontWeight: 'bold' }}
                    onClick={e => {
                      e.preventDefault()
                      keys.current = keys.current.filter((_, i) => index !== i)
                      onReorder(data.filter((_item, i) => index !== i))
                    }}
                  >
                    x
                  </button>
                </div>
              </div>

              <MemoizedRenderItem.current item={item} index={index} itemsRef={itemsRef} />
            </div>
            {hoveredIndex === index && draggedIndex !== index && (
              <div
                style={{
                  position: 'absolute',
                  width: horizontal ? spacing : '100%',
                  height: horizontal ? '100%' : spacing,
                  backgroundColor: dropColor,
                  borderRadius: 2,
                  [hoverPosition === 'before' ? (horizontal ? 'left' : 'top') : horizontal ? 'right' : 'bottom']: -spacing
                }}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

const scrollIfNeeded = (element, container, horizontal, padding) => {
  if (!element || !container) return

  const rect = element.getBoundingClientRect()
  const rectContainer = container.getBoundingClientRect()

  if (horizontal) {
    const scrollLeft = rect.left - rectContainer.left - padding
    const scrollRight = rect.right - rectContainer.right + padding
    if (scrollLeft < 0) container.scrollBy({ top: 0, left: scrollLeft, behavior: 'smooth' })
    else if (scrollRight > 0) container.scrollBy({ top: 0, left: scrollRight, behavior: 'smooth' })
  } else {
    const scrollUp = rect.top - padding
    const scrollDown = rect.bottom + padding - window.innerHeight
    if (scrollUp < 0) window.scrollBy({ top: scrollUp, left: 0, behavior: 'smooth' })
    else if (scrollDown > 0) window.scrollBy({ top: scrollDown, left: 0, behavior: 'smooth' })
  }
}

// Exemple d'utilisation :
const initialItems = [
  { id: 'h', content: 'h', order: 7 },
  { id: 'd', content: 'd', order: 3 },
  { id: 'n', content: 'n', order: 13 },
  { id: 'e', content: 'e', order: 4 },
  { id: 'a', content: 'a', order: 0 },
  { id: 'b', content: 'b', order: 1 },
  { id: 'c', content: 'c', order: 2 },
  { id: 'i', content: 'i', order: 8 },
  { id: 'p', content: 'p', order: 15 },
  { id: 'j', content: 'j', order: 9 },
  { id: 'f', content: 'f', order: 5 },
  { id: 'k', content: 'k', order: 10 },
  { id: 'g', content: 'g', order: 6 },
  { id: 'o', content: 'o', order: 14 },
  { id: 'u', content: 'u', order: 20 }
]
export const SomeComponant = () => {
  const [items, setItems] = useState(initialItems)

  // RenderItem est défini dans le composant pour avoir accès à setItems. Si on dépendait d'un state global ce ne serait pas nécessaire.
  // useRef est utilisé pour éviter de recréer le composant à chaque render.
  const RenderItem = useRef(({ item, itemsRef, index }) => {
    // Chaque élément n'est rafraichit que s'il change. Mais pour enregistrer des modifications, il peut avoir besoin de connaitre
    // l'ensemble de la liste. C'est pour cela qu'on lui passe itemsRef. Il s'agit d'un ref créée avec useRef, qui donne accès aux vraies
    // valeurs de la liste.
    console.log(itemsRef.current)

    return (
      <div style={{ padding: 10 }}>
        <input
          type="text"
          value={item?.txt || ''}
          style={{ marginRight: 15 }}
          onChange={e => {
            const txt = e.target?.value
            setItems(state => {
              state[index] = { ...state[index], txt }
              return [...state] // On doit renvoyer un nouveau tableau pour que React détecte le changement.
            })
          }}
        />
        {item.content?.toUpperCase()} - {item.order}
      </div>
    )
  })

  return (
    <div style={{}}>
      <DraggableList
        /// props obligatoires
        data={items}
        RenderItem={RenderItem.current}
        onReorder={items => setItems(items.map((item, index) => ({ ...item, order: index })))} // Fonction appelée pour chager l'ordre des items.
        /// Props optionnelles.
        horizontal={false} // Si on veut une liste horizontale.
        containerStyle={{}} // Style du container englobant la liste.
        itemContainerStyle={{}} // Style du container de chaque item.
        backgroundColor="#FFF" // Couleur de fond des items.
        dropColor="#7fb3d5" // Couleur de la ligne indiquant l'emplacement du drop.
        // Fonction pour extraire la clé unique de chaque item. Elle reçoit en props l'item et son index.
        // Par défaut le composant utilise l'index, c'est plus intéressant de garder une clé stable pour chaque élément lors du tri.
        // Par exemple, si on utilise les flèches plutôt que le drag/drop, une fois le bouton sélectionné on peut appuyer plusieurs fois sur
        // entrée pour déplacer le même élément vers le haut ou le bas. Si on utilise l'index on restera fixé sur la même position.
        // De plus, si c'est bien l'élément qui se déplace, et qu'il sort de l'écran, on aura un scorll automatique pour le suivre.
        keyExtractor={(item, index) => item.id} // Meilleure pratique, la clé ne changera pas lors de "onReorder".
        // keyExtractor={(item, index) => item.index} // Comportement par défaut. On ne suit pas vraiment les éléments lors du déplacement.
      />
    </div>
  )
}
