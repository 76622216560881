import React, { useRef } from 'react'

import { DraggableList } from 'components/draggableList'
import Action from '../action'

export const DraggableActions = ({ formData, formContext, required, onChange, onKeyChange, schema, ...props }) => {
  const RenderAction = useRef(({ item, itemsRef, index }) => {
    const handleChange = item => {
      const items = [...(itemsRef?.current || [])]
      items[index] = { ...item }
      onChange(items)
    }

    return (
      <div style={{ padding: 8 }}>
        <Action {...{ formData: item, formContext, schema: {} }} onChange={item => handleChange(item)} />
      </div>
    )
  })

  const handleAdd = e => {
    e.preventDefault()
    onChange([...(formData || []), {}])
  }

  return (
    <div>
      <label className="control-label">
        {schema?.title}
        {required && '*'}
      </label>
      <DraggableList
        data={formData || []}
        RenderItem={RenderAction.current}
        onReorder={items => onChange(items)}
        horizontal={true}
        itemContainerStyle={{ width: 300 }}
      />
      <div style={{ textAlign: 'right' }}>
        <button type="button" className="btn btn-info btn-add" style={{ width: 250 }} onClick={handleAdd}>
          <i className="glyphicon glyphicon-plus"></i>
        </button>
      </div>
    </div>
  )
}
