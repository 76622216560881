import generify from './generic'
import { user, conf, apiCache } from '../../stores'

const cache = { lastRequested: 0, list: [] }

export const sites = generify('experiences-service', 'sites', 'site')

sites.listActiveLite = async () => {
  // Si des sites existent dans le cache normal, le cache local est mis à jour.
  if (apiCache.sites.list()?.length > 0) {
    const newSites = apiCache.sites.list().filter(site => site.deleted !== true).map(site => ({ _id: site._id, name: site.name })) // prettier-ignore
    cache.list = newSites || []
    cache.lastRequested = Date.now()
  }

  // Si on a du cache local récent, pas besoin de faire la requête.
  if (cache.list?.length > 0 && Date.now() - cache.lastRequested < 5 * 60 * 1000) {
    return Promise.resolve(cache.list)
  }

  return fetch(conf.API_URL + '/experiences-service/sites?select=_id,name&filter={"deleted":{"$ne":true}}', {
    headers: { Authorization: 'Bearer ' + user.token },
    method: 'GET'
  })
    .then(res => res.json())
    .then(json => {
      if (json.code && json.msg) throw json.msg
      cache.list = json || []
      cache.lastRequested = Date.now()
      return json
    })
}
