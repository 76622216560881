import React, { useEffect, useState } from 'react'
import JsonSchemaForm from 'react-jsonschema-form'
import { Link } from 'react-router-dom'

import Loading from 'components/loading'
import { NavInSite } from 'components/nav'
import { postsApi } from 'lib/api/posts'
import Page from 'pages/page'
import { useSitePost } from 'stores/posts.store'
import { t } from 'stores/i18n.store'
import { RawJsonForm } from 'components/rawJsonForm'
import { notifications } from 'stores'

export const Post = props => {
  const { siteUid: siteId, postId } = props.match.params || {}

  const post = useSitePost(siteId, postId)
  const [formMode, setFormMode] = useState('form')
  const [editable, setEditable] = useState({})
  const [deleting, setDeleting] = useState(false)
  const [hasBeenDeleted, setHasBeenDeleted] = useState(false)

  useEffect(() => {
    if (!deleting) {
      setEditable(postId ? (post ? toEditable(post) : null) : {})
      if (postId && !post)
        postsApi.readSitePosts({ siteId }).then(posts => {
          if (!Array.isArray(posts) || !posts.find(post => post._id === postId)) backToSitePosts()
        })
    }
    // eslint-disable-next-line
  }, [post, deleting])

  // eslint-disable-next-line
  useEffect(() => void (hasBeenDeleted && backToSitePosts()), [hasBeenDeleted])

  const onSubmit = async ({ formData }) => {
    const id = Date.now()
    if (!formData?.title) formData.title = ''
    if (!formData?.teaserText) formData.teaserText = ''
    if (!formData?.text) formData.text = ''
    if (!formData?.locale) formData.locale = 'fr'

    try {
      if (postId) await postsApi.updatePost({ siteId, data: toPost(formData) })
      else {
        await postsApi
          .createPost({ siteId, data: toPost(formData) })
          .then(post => post?._id && props.history.push({ pathname: `/site/${siteId}/post/${post._id}` }))
      }
      notifications.set(id, { id, type: 'success', title: t('save-success'), autoClose: 1500 })
    } catch (error) {
      console.log('ERROR!!!', error)
      notifications.set(id, { id, type: 'danger', title: t('save-error'), content: error.toString(), autoClose: 2500 })
    }
  }

  const onDelete = () => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cet élément ?')) {
      setDeleting(true)
      postsApi
        .deletePost({ siteId, postId })
        .then(deleted => setHasBeenDeleted(deleted))
        .finally(() => setDeleting(false))
    }
  }

  const backToSitePosts = () => props.history.push({ pathname: `/site/${siteId}/posts` })

  return (
    <Page>
      <NavInSite />
      <div id="content" style={{ marginBottom: 30 }}>
        {editable ? (
          <div>
            <div style={{ marginBottom: 20, display: 'flex' }}>
              <div className="btn-group" role="group" style={{ flex: 1 }}>
                <Link to={`/site/${siteId}/posts`} className="btn btn-info" style={{ marginRight: 10 }}>
                  <i className="fa fa-arrow-left" aria-hidden="true" />
                </Link>
                <button className={`btn ${formMode === 'json' ? 'btn-info' : 'btn-default'}`} onClick={() => setFormMode('json')}>
                  {t('mode-json')}
                </button>
                <button className={`btn ${formMode === 'form' ? 'btn-info' : 'btn-default'}`} onClick={() => setFormMode('form')}>
                  {t('mode-form')}
                </button>
              </div>

              {postId && (
                <button className="btn btn-danger" onClick={() => onDelete()}>
                  <i className="fa fa-trash"></i>
                </button>
              )}
            </div>
            {formMode === 'json' ? (
              <RawJsonForm
                value={editable || {}}
                onChange={state => setEditable(state)}
                onSubmit={() => onSubmit({ formData: editable })}
              />
            ) : (
              <>
                <h2 style={{ marginBottom: 10 }}>{editable._id ? `Article "${editable.title}"` : 'Nouvel article'}</h2>
                <div style={{}}>
                  <JsonSchemaForm schema={schema} formData={editable} uiSchema={uiSchema} onSubmit={onSubmit}>
                    <button className="btn btn-info" type="submit">
                      Enregistrer
                    </button>
                  </JsonSchemaForm>
                </div>
              </>
            )}
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </Page>
  )
}

const toEditable = post => ({
  ...(post || {}),
  notBefore: post?.notBefore ? new Date(post.notBefore).toISOString().split('T')[0] : '',
  notAfter: post?.notAfter ? new Date(post.notAfter).toISOString().split('T')[0] : ''
})

const toPost = editable => ({
  ...editable,
  notBefore: editable?.notBefore ? new Date(editable.notBefore).getTime() : undefined,
  notAfter: editable?.notAfter ? new Date(editable.notAfter).getTime() : undefined
})

const schema = {
  type: 'object',
  required: ['type', 'title', 'text'],
  properties: {
    type: {
      type: 'string',
      title: "Type d'article",
      enum: ['event', 'news'],
      enumNames: ['Evénement', 'Nouveauté'],
      default: 'event'
    },
    title: { type: 'string', title: "Titre de l'article" },
    teaserText: { type: 'string', title: 'Phrase de teaser' },
    text: { type: 'string', title: "Contenu de l'article" },
    locale: {
      type: 'string',
      title: 'Langue',
      enum: ['fr', 'en', 'de'],
      enumNames: ['Français', 'Anglais', 'Allemand'],
      default: 'fr'
    },
    buttons: {
      type: 'array',
      title: 'Boutons',
      items: {
        type: 'object',
        required: ['label', 'type', 'value'],
        properties: {
          label: { type: 'string', title: 'Texte' },
          type: { type: 'string', title: 'Type de bouton', enum: ['url', 'expId'], enumNames: ['URL', 'Expérience'] },
          value: { type: 'string', title: 'Valeur' }
        }
      }
    },
    published: { type: 'boolean', title: 'Publié' },
    notBefore: { type: 'string', title: "L'article publié n'est pas actif avant cette date", format: 'date' },
    notAfter: { type: 'string', title: "L'article publié n'est plus actif après cette date", format: 'date' }
  }
}

const uiSchema = {
  type: { classNames: 'inline' },
  locale: { classNames: 'inline' },
  text: {
    'ui:widget': 'textarea',
    'ui:options': {
      rows: 8
    }
  }
}
