import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import * as api from '../../lib/api'
import { SoundPicker } from './resourcepicker'
import ModulePicker from './modulepicker'
import PointPicker from './pointpicker'
import TrackPicker from './trackpicker'
import TimerPicker from './timerpicker'
import ScenarioPicker from './scenariopicker'
import MenuPicker from './menupicker'
import MapPicker from './mappicker'
import { PopupPicker } from './popupPicker'

export default class Action extends Component {
  state = {
    scenarios: [],
    points: [],
    tracks: [],
    menus: [],
    maps: [],
    popups: [],
    loadingScenarios: false,
    loadingTracks: false,
    loadingPoints: false,
    loadingMenus: false,
    loadingMaps: false
  }

  fieldNameFromType(type) {
    switch (type) {
      case 'hide_point':
      case 'show_point':
      case 'enable_point':
      case 'disable_point':
        return 'point'
      case 'load_scenario':
        return 'scenario'
      case 'load_track':
        return 'track'
      case 'play_sound':
        return 'sound'
      case 'hide_menu':
      case 'show_menu':
        return 'menu'
      case 'hide_map':
      case 'show_map':
        return 'map'
      default:
        return ''
    }
  }

  componentDidMount() {
    this.populateScenarios()
    this.populateTracks()
    this.populatePoints()
    this.populateExperience()
    this.populateModules()
    this.populateMenus()
    this.populateMaps()
  }

  componentWillReceiveProps() {
    this.populateScenarios()
    this.populateTracks()
    this.populatePoints()
    this.populateExperience()
    this.populateModules()
    this.populateMenus()
    this.populateMaps()
  }

  populateModules() {
    if (!this.props.formContext.scenarioUid || this.state.loadingModules) return

    this.setState({ loadingModules: true })
    api.modules.getFromScenario(this.props.formContext.scenarioUid).then(modules => {
      this.setState({ modules, loadingModules: false })
    })
  }

  populateExperience() {
    if (this.state.loadingExperience) return

    this.setState({ loadingExperience: true })
    api.experiences.get(this.props.formContext.experienceUid).then(experience => {
      this.setState({ experience, loadingExperience: false })
      this.setState({ popups: experience?.popups || [] })
    })
  }

  populateScenarios() {
    if (this.state.scenarios.length === 0 && this.state.loadingScenario) return

    this.setState({ loadingScenarios: true })
    api.scenarios.getFromExperience(this.props.formContext.experienceUid).then(scenarios => {
      this.setState({ scenarios, loadingScenarios: false })
    })
  }

  populateTracks() {
    if (this.state.tracks.length === 0 && this.state.loadingTracks) return

    this.setState({ loadingScenario: true })
    api.tracks.getFromExperience(this.props.formContext.experienceUid).then(tracks => {
      this.setState({ tracks, loadingTrack: false })
    })
  }

  populatePoints() {
    if (this.state.points.length === 0 && this.state.loadingPoints) return

    this.setState({ loadingPoints: true })
    api.points.getFromExperience(this.props.formContext.experienceUid).then(points => {
      this.setState({ points, loadingPoints: false })
    })
  }

  populateMenus() {
    if (this.state.menus.length === 0 && this.state.loadingMenus) return

    this.setState({ loadingMenus: true })
    api.tracks.getFromExperience(this.props.formContext.experienceUid).then(tracks => {
      const menus = tracks.reduce((acc, track) => {
        const menu = track.menu?.filter(m => m !== undefined)
        if (menu.length > 0) acc = acc.concat(menu)
        return acc
      }, [])
      this.setState({ menus, loadingMenus: false })
    })
  }

  populateMaps() {
    if (this.state.maps.length === 0 && this.state.loadingMaps) return

    this.setState({ loadingMaps: true })
    api.maps.getFromExperience(this.props.formContext.experienceUid).then(maps => this.setState({ maps, loadingMaps: false }))
  }

  changeType = e => {
    const change = { ...this.props.formData, type: e.target.value }
    this.props.onChange(change)
  }

  changeParameter = e => {
    const fieldName = this.fieldNameFromType(this.props.formData.type)
    const change = { type: this.props.formData.type }
    change[fieldName] = e.target.value
    this.props.onChange(change)
  }

  render() {
    const { type } = this.props.formData || {}
    const id = this.props.id
    const { experienceUid, siteUid } = this.props.formContext || {}
    let fieldName = ''
    let button = null
    let picker = null
    switch (type) {
      case 'add_score':
        fieldName = 'score'
        picker = (
          <div className="form-group">
            <input
              type="number"
              className="form-control"
              placeholder="Score additionnel"
              value={this.props.formData.score || ''}
              onChange={e => this.props.onChange({ type, score: e.target.value })}
            />
          </div>
        )

        break
      case 'hide_point':
      case 'show_point':
      case 'enable_point':
      case 'disable_point':
        picker = (
          <PointPicker
            formData={this.props.formData.point}
            uiSchema={{}}
            schema={{ title: '' }}
            formContext={{ ...this.props.formContext, points: this.state.points, tracks: this.state.tracks }}
            onChange={uid => this.props.onChange({ type, point: uid })}
          />
        )
        fieldName = 'point'

        const currentPoint = this.state.points.find(p => p._id === this.props.formData.point)
        button = currentPoint && (
          <Link
            to={`/site/${siteUid}/experience/${experienceUid}/track/${currentPoint.trackId}/point/${currentPoint._id}`}
            className="btn btn-default"
          >
            Voir
          </Link>
        )
        break
      case 'load_scenario':
        picker = (
          <ScenarioPicker
            formData={this.props.formData.scenario}
            uiSchema={{}}
            schema={{
              title: ''
            }}
            formContext={{
              ...this.props.formContext,
              scenarios: this.state.scenarios
            }}
            onChange={uid => {
              this.props.onChange({ type: 'load_scenario', scenario: uid })
            }}
          />
        )
        break
      case 'load_module':
        picker = (
          <ModulePicker
            formData={this.props.formData.module}
            uiSchema={{}}
            schema={{
              title: ''
            }}
            formContext={{
              ...this.props.formContext,
              modules: this.state.modules
            }}
            onChange={uid => {
              this.props.onChange({ type: 'load_module', module: uid })
            }}
          />
        )
        break
      case 'load_track':
        fieldName = 'track'

        picker = (
          <TrackPicker
            formData={this.props.formData.track}
            uiSchema={{}}
            schema={{
              title: ''
            }}
            formContext={{
              ...this.props.formContext,
              tracks: this.state.tracks
            }}
            onChange={uid => {
              this.props.onChange({ type: 'load_track', track: uid })
            }}
          />
        )
        break

      case 'start_timer':
      case 'stop_timer':
        fieldName = 'timer'
        button = this.props.formData.timer && (
          <Link
            to={'/site/' + siteUid + '/experience/' + experienceUid + '/timer/' + this.props.formData[fieldName]}
            className="btn btn-default"
          >
            Voir
          </Link>
        )

        picker = (
          <TimerPicker
            formData={this.props.formData.timer}
            uiSchema={{}}
            schema={{
              title: ''
            }}
            formContext={{
              ...this.props.formContext,
              experience: this.state.experience
            }}
            onChange={uid => {
              this.props.onChange({ type, timer: uid })
            }}
          />
        )
        break

      case 'play_sound':
        fieldName = 'sound'
        picker = (
          <SoundPicker
            formData={this.props.formData.sound}
            uiSchema={{}}
            schema={{
              title: 'Son'
            }}
            formContext={this.props.formContext}
            onChange={uid => {
              this.props.onChange({ type: 'play_sound', sound: uid })
            }}
          />
        )
        break
      case 'show_popup':
        fieldName = 'sound'
        picker = (
          <PopupPicker
            popups={this.state.popups}
            formData={this.props.formData?.popup}
            // uiSchema={{}}
            // schema={{ title: '' }}
            formContext={{ ...this.props.formContext }}
            onChange={uid => {
              this.props.onChange({ type: 'show_popup', popup: uid })
            }}
          />
        )
        break

      case 'disable_all_points':
      case 'hide_all_points':
      case 'enable_all_points':
      case 'show_all_points':
      case 'stop_all_timers':
        // no picker for globals actions
        break
      case 'start_game_timer':
        break
      case 'stop_game_timer':
        break
      case 'reset_game_timer':
        break
      case 'show_menu':
      case 'hide_menu':
        fieldName = 'menu'
        picker = (
          <MenuPicker
            formData={this.props.formData.menu}
            uiSchema={{}}
            schema={{ title: '' }}
            formContext={{ ...this.props.formContext, menus: this.state.menus }}
            onChange={uid => this.props.onChange({ type, menu: uid })}
          />
        )
        break
      case 'show_map':
      case 'hide_map':
        fieldName = 'map'
        picker = (
          <MapPicker
            formData={this.props.formData.map}
            uiSchema={{}}
            schema={{ title: '' }}
            formContext={{ ...this.props.formContext, maps: this.state.maps }}
            onChange={uid => this.props.onChange({ type, map: uid })}
          />
        )
        break
      case 'add_switch':
        picker = (
          <div className="form-group field field-string" style={{ minWidth: '300px' }}>
            <label className="control-label">{'clé'}</label>
            <input
              type="text"
              className="form-control"
              onChange={e => {
                this.props.onChange({ type, key: e.target.value, value: this.props.formData.value || '' })
              }}
              value={this.props.formData.key || ''}
              style={{ width: '90%' }}
            />
            <label className="control-label">{'valeur'}</label>
            <input
              type="text"
              className="form-control"
              onChange={e => this.props.onChange({ type, key: this.props.formData.key || '', value: e.target.value })}
              value={this.props.formData.value || ''}
              style={{ width: '90%' }}
            />
          </div>
        )
        break
      case 'delete_switch':
        picker = (
          <div className="form-group field field-string" style={{ minWidth: '300px' }}>
            <label className="control-label">{'clé'}</label>
            <input
              type="text"
              className="form-control"
              onChange={e => this.props.onChange({ type, key: e.target.value })}
              value={this.props.formData.key || ''}
            />
          </div>
        )
        break

      default:
    }

    return (
      <div className="form-group field field-string">
        <label className="control-label" htmlFor={this.props.id}>
          {this.props.schema.title}
        </label>
        <select id={id} className="form-control" value={type} onChange={this.changeType}>
          <option value="" />
          <optgroup label="Actions diverses en jeu">
            <option value="add_score">Ajouter des points au score</option>
            <option value="play_sound">Lire un son</option>
            <option value="show_popup">Afficher un popup</option>
            <option value="stop_session">Terminer la partie</option>
            <option value="end_session">Terminer et quitter la partie</option>
          </optgroup>

          <optgroup label="Etat des points">
            <option value="enable_point">Activer un point</option>
            <option value="disable_point">Désactiver un point</option>
            <option value="enable_all_points">Activer tous les points</option>
            <option value="disable_all_points">Désactiver tous les points</option>
          </optgroup>

          <optgroup label="Visibilité des points">
            <option value="show_point">Afficher un point</option>
            <option value="hide_point">Cacher un point</option>
            <option value="show_all_points">Afficher tous les points</option>
            <option value="hide_all_points">Cacher tous les points</option>
          </optgroup>

          <optgroup label="Navigation">
            {this.props.formContext.scenarioUid && <option value="load_module">Changer de module</option>}
            <option value="load_scenario">Changer de scénario</option>
            <option value="load_track">Lancer un parcours</option>
          </optgroup>

          <optgroup label="Gestion du temps">
            <option value="start_timer">Démarrer un compte à rebours</option>
            <option value="stop_timer">Arreter un compte à rebours</option>
            <option value="stop_all_timers">Arreter tous les comptes à rebours</option>
            <option value="start_game_timer">Démarrer le chronomètre</option>
            <option value="stop_game_timer">Arrêter le chronomètre</option>
            <option value="reset_game_timer">Réinitialiser le chronomètre</option>
          </optgroup>

          <optgroup label="Eléments du parcours">
            <option value="show_menu">Afficher un élément du menu</option>
            <option value="hide_menu">Masquer un élément du menu</option>
            <option value="show_map">Afficher une carto</option>
            <option value="hide_map">Masquer une carto</option>
          </optgroup>

          <optgroup label="Switch">
            <option value="add_switch">Ajouter un switch</option>
            <option value="delete_switch">Supprimer un switch</option>
          </optgroup>
        </select>
        {picker}

        {button}
      </div>
    )
  }
}
