import * as api from '../api'

/**
 * Vérification de l'extension.
 */
export const extensionCheck = async extension => {
  const checkResult = { warnings: [], errors: [], nb: 0 }

  checkResult.nb++
  if (!extension?.experienceId) {
    checkResult.errors.push({ text: "L'extension doit être associée à une expérience." })
  }

  checkResult.nb++
  if (!extension?.scenarioId) {
    checkResult.errors.push({ text: "L'extension doit définir un scénario." })
  }

  checkResult.nb++
  if (extension.published && extension.experiences.length === 0) {
    checkResult.warnings.push({ text: "L'extension est publiée mais n'est appliquée sur aucune expérience." })
  }

  try {
    checkResult.nb++ // Récupération de la release.
    const release = await api.releases.listFromExperience('prod', extension.experienceId || '').catch(() => {
      checkResult[extension.published ? 'errors' : 'warnings'].push({
        text: "L'expérience associée à l'extension n'est pas en production."
      })
    })

    if (release?._id) {
      const scenario = release?.scenarios?.[extension.scenarioId]

      // Si le scénario n'est pas en prod.
      checkResult.nb++
      if (!scenario) {
        checkResult[extension.published ? 'errors' : 'warnings'].push({
          text: "Le scénario associé à l'extension n'existe pas en prod."
        })
      } else {
        // Si le scénario contient des modules investigation.
        checkResult.nb++
        const nbcluedo = scenario.modules?.filter(m => m.type === 'cluedo').length
        if (nbcluedo) {
          checkResult.errors.push({
            text: `Le scénario contient ${nbcluedo} module${nbcluedo > 1 ? 's' : ''} investigation.`
          })
        }

        // Si le scénario contient des modules investigation.
        checkResult.nb++
        const nbSwitch = scenario.modules?.filter(m => m.type === 'switch').length
        if (nbSwitch) {
          checkResult.warnings.push({
            text: `Le scénario contient ${nbSwitch} module${nbSwitch > 1 ? 's' : ''} Switch. À gérer avec précaution.`
          })
        }

        // Si le scénario contient des actions de navigation vers un parcours.
        checkResult.nb++
        const nbLoadTrack = findActions(scenario, ['load_track'])
        if (nbLoadTrack > 0)
          checkResult.errors.push({
            text: `Le scénario ou ses modules contiennent ${nbLoadTrack} action${
              nbLoadTrack > 1 ? 's' : ''
            } déclenchant une navigation vers un parcours.`
          })

        // Si le scénario contient des actions de navigation vers un parcours.
        checkResult.nb++
        const nbLoadScenario = findActions(scenario, ['load_scenario'])
        if (nbLoadScenario > 0)
          checkResult.errors.push({
            text: `Le scénario ou ses modules contiennent ${nbLoadScenario} action${
              nbLoadScenario > 1 ? 's' : ''
            } déclenchant une navigation vers un autre scénario.`
          })

        // Si le scénario contient des actions d'ajout/suppression de switch.
        checkResult.nb++
        const nbEditSwitch = findActions(scenario, ['add_switch', 'delete_switch'])
        if (nbEditSwitch > 0)
          checkResult.warnings.push({
            text: `Le scénario ou ses modules contiennent ${nbEditSwitch} action${
              nbEditSwitch > 1 ? 's' : ''
            } de modification d'un Switch (ajout ou suppression). À gérer avec précaution.`
          })
      }
    }
  } catch (error) {}

  return checkResult
}

/** Fonction asynchrone et récursive, permettant de parcourir un objet, et effectuer certaines modifications. */
const findActions = (obj, actionsType = []) => {
  let count = 0

  // Pour chaque propriété de l'objet...
  for (let key in obj) {
    if (!obj.hasOwnProperty(key)) continue // Passe à la suite si ce n'est pas une propriété directe de l'objet.

    // Incrément si la clé vaut "type", et que la valeur est dans "actionsType".
    if (key === 'type' && actionsType.includes(obj[key])) count++
    // Sinon, si la valeur est un objet, on rappelle la fonction récursivement pour récupérer le nombre d'occurrences.
    else if (typeof obj[key] === 'object' && obj[key] !== null) count += findActions(obj[key], actionsType)
  }

  // Retourne les occurences trouvées.
  return count
}
