import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Loading from 'components/loading'
import { NavInSite } from 'components/nav'
import { postsApi } from 'lib/api/posts'
import Page from 'pages/page'
import { useSitePosts } from 'stores/posts.store'

export const Posts = props => {
  // const siteId = useRef(props.match.params.siteUid).current
  const { siteUid: siteId } = props.match.params || {}

  const posts = useSitePosts(siteId)
  const [hovered, setHovered] = useState(undefined)

  // eslint-disable-next-line
  useEffect(() => void (!posts && fetchData()), [])
  const fetchData = () => postsApi.readSitePosts({ siteId })

  return (
    <Page>
      <NavInSite />
      <div style={{ display: 'flex', flexDirection: 'column', margin: 10, width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15, alignItems: 'center' }}>
          <h1 style={{ marginRight: 10 }}>Articles</h1>
          <button onClick={() => fetchData()} className="btn btn-default">
            <h1>
              <i className="fa fa-refresh" aria-hidden="true"></i>
            </h1>
          </button>
        </div>

        {posts === null ? (
          <Loading />
        ) : (
          <div style={{ marginBottom: 20 }}>
            <Link to={`/site/${siteId}/post`} className="btn btn-info">
              <i className="fa fa-plus" style={{ marginRight: 5 }} />
              Ajouter un article
            </Link>
          </div>
        )}

        {Array.isArray(posts) && (
          <>
            {posts.length > 0 ? (
              <div style={{ display: 'flex', width: '100%' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th style={{ minWidth: 150, width: '20%' }}>Date de création</th>
                      <th style={{ minWidth: 150, width: '50%' }}>Titre</th>
                      <th style={{ minWidth: 80, width: '15%' }}>Publié</th>
                      <th style={{ minWidth: 80, width: '15%' }}>Actif</th>
                    </tr>
                  </thead>
                  <tbody>
                    {posts.map((post, index) => {
                      const pathname = `/site/${siteId}/post${post?._id ? `/${post._id}` : ''}`
                      return (
                        <tr
                          onMouseEnter={() => setHovered(index)}
                          onMouseLeave={() => setHovered(undefined)}
                          onClick={() => props.history.push({ pathname })}
                          style={{ cursor: 'pointer', backgroundColor: hovered === index && '#f0f8ff' }}
                          key={post._id}
                        >
                          <td>{new Date(post.createdAt).toLocaleString()}</td>
                          <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{post.title}</td>
                          <td>{post.published ? 'oui' : 'non'}</td>
                          <td>{isActive(post) ? 'oui' : 'non'}</td>
                          <td>
                            <button className="btn btn-default"><i className="fa fa-edit" /></button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>Aucun article</div>
            )}
          </>
        )}
      </div>
    </Page>
  )
}

const isActive = post => {
  const now = Date.now()
  return post.published && post.notBefore < now && post.notAfter > now
}
