import { toJS } from 'mobx'
import React, { Component } from 'react'

import { NavInScenario } from '../../components/nav'

import * as api from '../../lib/api'
import createScenarioAction from '../../lib/createscenarioaction'
import { apiCache } from '../../stores'
import { Form } from '../../components/form'
import { Page } from '../'
import checkScenario from '../../lib/check/scenario'

class ScenarioForm extends Component {
  state = {
    loading: true
  };

  async populateInitialData (siteUid, experienceUid, scenarioUid) {
    this.setState({ loading: true })
    await api.experiences.getFromSite(siteUid)
    if (scenarioUid) {
      const scenario = await api.scenarios.get(scenarioUid)
      await api.modules.getFromScenario(scenarioUid)
      const checks = await checkScenario(scenario)
      this.setState({ checks, loading: false })
    } else {
      this.setState({ loading: false })
    }
  }

  componentDidMount () {
    const { siteUid, experienceUid, scenarioUid } = this.props.match.params
    this.populateInitialData(siteUid, experienceUid, scenarioUid)
  }

  componentWillReceiveProps (nextProps) {
    const { siteUid, experienceUid } = this.props.match.params

    if (
      nextProps.match.params.scenarioUid !== this.props.match.params.scenarioUid
    ) {
      this.populateInitialData(
        siteUid,
        experienceUid,
        nextProps.match.params.scenarioUid
      )
    }
  }

  save = async formData => {
    let { modules, ...scenario } = formData
    if (!modules) modules = []
    const { experienceUid, siteUid, scenarioUid } = this.props.match.params
    const oldModules = apiCache.modules.getFrom('scenarios', scenarioUid) || []
    scenario.siteId = siteUid
    scenario.experienceId = experienceUid

    scenario = await api.scenarios.upsert(scenario)
    for (let counter = 0; counter < oldModules.length; counter++) {
      const oldModule = oldModules[counter]
      const newModule = modules.find(m => m._id === oldModule._id)
      const newModuleIndex = modules.findIndex(m => m._id === oldModule._id)
      if (!newModule) {
        await api.modules.remove(oldModule._id)
      } else {
        if (
          oldModule.title !== newModule.title ||
          oldModule.type !== newModule.type ||
          oldModule.order !== newModuleIndex ||
          oldModule.internalName !== newModule.internalName
        ) {
          await api.modules.update(newModule._id, {
            title: newModule.title || null,
            type: newModule.type,
            internalName: newModule.internalName || null,
            experienceId: experienceUid,
            order: newModuleIndex
          })
        } else {
          console.log('no change')
        }
      }
    }

    // modules created
    console.log('created', modules)
    for (let order = 0; order < modules.length; order++) {
      const moduleCreated = modules[order]
      if (!moduleCreated.id) {
        console.log('create module')
        await api.modules.create({
          title: moduleCreated.title,
          internalName: moduleCreated.internalName,
          type: moduleCreated.type,
          experienceId: experienceUid,
          scenarioId: scenario._id,
          order
        })
      }
    }

    console.log('will save ', scenario)
    scenario = await createScenarioAction(scenario)
    console.log('createScenarioAction ', scenario)
    await api.scenarios.upsert(scenario)
    const checks = await checkScenario(scenario)
    this.setState({ checks })
    if (!scenarioUid) {
      const url = this.props.match.url + scenario._id
      this.props.history.push(url)
    }
  };

  delete = async () => {
    const { siteUid, experienceUid, scenarioUid } = this.props.match.params
    await api.scenarios.remove(scenarioUid)
    const url = '/site/' + siteUid + '/experience/' + experienceUid
    this.props.history.push(url)
  };

  rollBack = async patchId => {
    const { scenarioUid } = this.props.match.params
    const scenario = await api.scenarios.rollBackToPatch(scenarioUid, patchId)
    this.setState({ scenario })
  };

  duplicate = async () => {
    const { siteUid, experienceUid, scenarioUid } = this.props.match.params
    const clone = toJS(apiCache.scenarios.get(scenarioUid))
    delete clone._id
    clone.name = clone.name + ' (copie)'
    const modules = await api.modules.getFromScenario(scenarioUid)
    const scenarioCreated = await api.scenarios.create(clone)
    for (let counter = 0; counter < modules.length; counter++) {
      const m = modules[counter]
      const clone = toJS(m)
      delete clone._id
      clone.scenarioId = scenarioCreated._id
      await api.modules.create(clone)
    }
    const url =
      '/site/' +
      siteUid +
      '/experience/' +
      experienceUid +
      '/scenario/' +
      scenarioCreated._id
    this.props.history.push(url)
  };

  render () {
    const { siteUid, experienceUid, scenarioUid } =
      this.props.match.params || {}
    const { loading, checks } = this.state
    let scenario = { experienceId: experienceUid }

    let modules = []

    const experiences = toJS(apiCache.experiences.getFrom('sites', siteUid))
    const experience = toJS(apiCache.experiences.get(experienceUid))

    if (scenarioUid) {
      scenario = toJS(apiCache.scenarios.get(scenarioUid))
      modules = toJS(apiCache.modules.getFrom('scenarios', scenarioUid))
      if (modules) {
        modules.sort((a, b) => (a.order || 0) - (b.order || 0))
      }
    }

    return (
      <Page>
        {scenario && scenario._id && <NavInScenario />}
        <div id='content'>
          {!loading &&
            checks &&
            checks.errors.map((e, i) => (
              <p className='bg-danger' key={i}>
                {e.text}
              </p>
            ))}
          {!loading &&
            checks &&
            checks.warnings.map((e, i) => (
              <p className='bg-warning' key={i}>
                {e.text}
              </p>
            ))}
          {!loading && checks && (
            <p className='bg-success'>
              {checks.nb - checks.warnings.length - checks.errors.length}{' '}
              vérifications réussies
            </p>
          )}
          {!loading && (
            <Form
              formPath='scenario'
              onSubmit={this.save}
              onDuplicate={this.duplicate}
              onDelete={this.delete}
              experienceUid={experienceUid}
              initialFormData={{
                ...scenario,
                modules
              }}
              formContext={{ siteUid, experienceUid, experiences, experience }}
              listHistory={() =>
                api.scenarios.listAvailableRollBack(scenarioUid)}
              rollBackToPatch={this.rollBack}
            />
          )}
        </div>
        <div id='app-preview' />
      </Page>
    )
  }
}

export default ScenarioForm
