import React from 'react'

import Page from 'pages/page'
import { NavInExperience } from 'components/nav'

export const SwitchManager = () => {
  return (
    <Page>
      <NavInExperience />
      <div id="content">
        <div>En cours de création...</div>
      </div>
    </Page>
  )
}
