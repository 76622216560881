import React, { useRef } from 'react'

import { DraggableList } from 'components/draggableList'
import Moduletypepicker from '../moduletypepicker'

export const DraggableModules = ({ formData, formContext, required, onChange, onKeyChange, schema, ...props }) => {
  const RenderModule = useRef(({ item, itemsRef, index }) => {
    const handleChange = (key, value) => {
      const items = [...(itemsRef?.current || [])]
      items[index] = { ...items[index], [key]: value }
      onChange(items)
    }

    return (
      <div style={{ padding: 8 }}>
        <Moduletypepicker {...{ formData: item.type, formContext, schema: {} }} onChange={type => handleChange('type', type)} />
        <div className="form-group field field-string">
          <label className="control-label" htmlFor={`root_modules_${index}_title`}>
            Titre
          </label>
          <input
            className="form-control"
            id={`root_modules_${index}_title`}
            type="text"
            value={item.title || ''}
            onChange={e => handleChange('title', e.target.value)}
          />
        </div>
        <div className="form-group field field-string">
          <label className="control-label" htmlFor={`root_modules_${index}_internalName`}>
            Nom interne (non visible par le joueur)
          </label>
          <input
            className="form-control"
            id={`root_modules_${index}_internalName`}
            type="text"
            value={item.internalName || ''}
            onChange={e => handleChange('internalName', e.target.value)}
          />
        </div>
      </div>
    )
  })

  const handleAdd = e => {
    e.preventDefault()
    onChange([...(formData || []), { order: formData?.length || 0 }])
  }

  return (
    <div>
      <label className="control-label">
        {schema?.title}
        {required && '*'}
      </label>
      <DraggableList
        data={formData || []}
        RenderItem={RenderModule.current}
        onReorder={items => onChange(items)}
        horizontal={true}
        itemContainerStyle={{ width: 300 }}
      />
      <div style={{ textAlign: 'right' }}>
        <button type="button" className="btn btn-info btn-add" style={{ width: 250 }} onClick={handleAdd}>
          <i className="glyphicon glyphicon-plus"></i>
        </button>
      </div>
    </div>
  )
}
